import React from "react";
import ReactScrollbars from "react-scrollbars-custom";
import { AutoSizer } from "react-virtualized";

import Thumb from "./views/Thumb";
import Track from "./views/Track";
import Wrapper from "./views/Wrapper";

const ScrollArea = props => {
  const { children } = props;

  const renderWrapper = (wrapperProps, width, height) => {
    const { elementRef, ...restProps } = wrapperProps;

    return (
      <Wrapper
        {...restProps}
        ref={elementRef}
        style={{
          width: width,
          height: height
        }}
      />
    );
  };

  const renderTrack = trackProps => {
    const { elementRef, ...restProps } = trackProps;

    return (
      <Track
        {...restProps}
        ref={elementRef}
      />
    );
  };

  const renderThumb = thumbProps => {
    const { elementRef, ...restProps } = thumbProps;

    return (
      <Thumb
        {...restProps}
        ref={elementRef}
      />
    );
  };

  return (
    <Wrapper>
      <AutoSizer>
        {
          ({ width, height }) => (
            <ReactScrollbars
              style={{ height, width }}
              width={width}
              height={height}
              permanentTrackY={true}
              noDefaultStyles={true}
              wrapperProps={{
                renderer: props => {
                  return renderWrapper(props, width, height);
                }
              }}
              trackYProps={{
                renderer: renderTrack
              }}
              thumbYProps={{
                renderer: renderThumb
              }}
            >
              { children }
            </ReactScrollbars>
          )
        }
      </AutoSizer>
    </Wrapper>
  );
};

export default ScrollArea;
