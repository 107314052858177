import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Plus = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill="none"
      stroke={color}
      strokeLinecap="square"
      strokeWidth="1.5"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line x1="1" y1="8" x2="15" y2="8"></line>
        <line x1="8" y1="1" x2="8" y2="15"></line>
      </g>
    </svg>
  );
});

Plus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Plus.defaultProps = {
  color: "currentColor"
}

export default Plus;
