import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled, { withTheme } from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { LeftArrow, RightArrow } from "@nowadays/UI/Icon";

const DIRECTIONS = {
  LEFT: "left",
  RIGHT: "right"
};

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledNavButton = styled.button.attrs(props => {
  const {
    className,
    active,
    direction,
    focused,
    hovered
  } = props;

  return {
    className: classNames(
      className,
      "about__aspirations__carousel-nav-button", {
        "about__aspirations__carousel-nav-button--left": direction === DIRECTIONS.LEFT,
        "about__aspirations__carousel-nav-button--right": direction === DIRECTIONS.RIGHT,

        "about__aspirations__carousel-nav-button--is-active": active,
        "about__aspirations__carousel-nav-button--is-focused": focused,
        "about__aspirations__carousel-nav-button--is-hovered": hovered
      })
  };
})`
  ${ buttonResets }

  ${({ theme: { borders, colors, space } }) => `
    align-items: flex-end;
    display: flex;
    flex: 1 0 0;
    height: ${ space.small };

    &.about__aspirations__carousel-nav-button--left {
      justify-content: flex-start;
    }

    &.about__aspirations__carousel-nav-button--right {
      justify-content: flex-end;
    }

    // :focus managed by KeyboardFocusContainer
    &.about__aspirations__carousel-nav-button--is-focused
    {
      outline: auto;
    }

    svg {
      vertical-align: top;
      height: ${ space.xxSmall };
    }
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      height: ${ space.medium };

      && {
        svg {
          height: ${ space.xSmall };
        }
      }
    `}
  `}
`;

const NavButton = React.forwardRef(({ direction, focused, theme, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledNavButton
        {...getFocusProps({
          ref,
          direction,
          ...other,
          focused: focused || keyboardFocused
        })}
      >
        {
          direction === DIRECTIONS.RIGHT ? (
            <RightArrow color={theme.colors.white}/>
          ) : (
            <LeftArrow color={theme.colors.white}/>
          )
        }
      </StyledNavButton>
    )}
  </KeyboardFocusContainer>
));

NavButton.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.oneOf(
    [
      DIRECTIONS.LEFT,
      DIRECTIONS.RIGHT
    ]
  ),
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

NavButton.defaultProps = {
  active: false,
  direction: DIRECTIONS.RIGHT,
  focused: false,
  hovered: false
};

export default withTheme(NavButton);
