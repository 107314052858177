import React from "react";

import MainContentPanel from "./views/MainContentPanel";

const MainContent = props => {
  const { children } = props;

  return (
    <MainContentPanel>
      { children }
    </MainContentPanel>
  );
};

MainContent.propTypes = {};

MainContent.defaultProps = {};

export default MainContent;
