import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Instagram = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeLinecap="square"
      strokeWidth="1"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M1.01,807.89c0-178.59,0-357.19,0-535.78c1.87-10.63,3.23-21.38,5.69-31.87
          C34.92,119.77,108.4,42.39,227.35,8.88C242.09,4.72,257.49,2.9,272.59,0c178.25,0,356.49,0,534.74,0
          c10.66,1.89,21.36,3.52,31.96,5.7c140.26,28.82,239.22,149.51,239.51,292.94c0.33,160.91,0.27,321.82-0.17,482.73
          c-0.05,19.54-1.36,39.56-5.72,58.52c-27.72,120.5-101.47,197.77-220.36,231.25c-14.74,4.15-30.13,5.97-45.21,8.87
          c-178.25,0-356.49,0-534.74,0c-10.66-1.89-21.44-3.26-31.96-5.74C120.43,1045.89,43.18,972.3,9.83,853.06
          C5.71,838.33,3.89,822.96,1.01,807.89z M539.95,983.25c0,0.16,0,0.33,0,0.49c78.24,0,156.49-0.05,234.73,0.03
          c23.97,0.02,47.47-2.59,70.36-10.22c78.24-26.08,138.69-101.44,138.07-197.32c-1.03-157.49-0.3-314.99-0.26-472.48
          c0.01-23.67-2.7-46.86-10.19-69.44c-26.08-78.6-101.54-138.97-196.94-138.34c-157.18,1.04-314.37,0.3-471.56,0.26
          c-23.63-0.01-46.76,2.73-69.31,10.21c-77.76,25.8-138.7,101.11-138.06,197.32c1.04,157.49,0.33,314.99,0.23,472.48
          c-0.02,26.94,3.99,53,13.36,78.37c26.95,72.9,100.34,127.19,178.01,128.36C372.23,984.22,456.1,983.25,539.95,983.25z
           M817.76,539.91c0.06,153.11-124.62,278.22-277.46,278.44c-153.22,0.22-278.42-125.28-278.16-278.79
          c0.27-153.16,125.16-278.02,277.98-277.91C692.96,261.76,817.71,386.79,817.76,539.91z M721.52,539.86
          c-0.11-100.94-81.37-182.12-181.97-181.78c-100.45,0.34-180.84,80.89-181.16,181.53c-0.33,100.82,80.66,182.2,181.43,182.31
          C640.48,722.03,721.64,640.72,721.52,539.86z M899.7,250.3c-0.41-38.13-31.78-69.48-69.98-69.94
          c-38.05-0.46-70.55,31.88-70.63,70.28c-0.09,38.6,31.71,70.28,70.46,70.2C868.21,320.76,900.12,288.68,899.7,250.3z"/>
      </g>
    </svg>
  );
});

Instagram.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Instagram.defaultProps = {
  color: "currentColor"
}

export default Instagram;
