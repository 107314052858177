import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const LargeParagraphStyles = css`
  ${({ theme: { fontSizes, lineHeights, measures }}) => `
    font-size: ${ fontSizes.large };
    line-height: ${ lineHeights.large };

    & + & {
      margin-top: ${ lineHeights.large };
    }

    &.paragraph--with-measure {
      max-width: ${ measures.large };
      width: 100%;
    }
  `}
`;

const LargeParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__large-paragraph")
    };
  }
)`
  ${ LargeParagraphStyles }
`;

LargeParagraph.propTypes = {
  ...Paragraph.propTypes
};

LargeParagraph.defaultProps = {};

export default LargeParagraph;
