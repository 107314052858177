import classNames from "classnames";
import styled from "styled-components";

const MobileMenu = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__mobile-menu"
    )
  };
})`
  ${() => `
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  `}
`;

MobileMenu.propTypes = {};

MobileMenu.defaultProps = {};

export default MobileMenu;
