import React from "react";
import Helmet from "react-helmet";

import favicon16Src from "@nowadays/Assets/images/favicons/favicon-16x16.png";
import favicon32Src from "@nowadays/Assets/images/favicons/favicon-32x32.png";

const Metadata = props => {
  const { metadata={}, path="" } = props;

  const defaultTitle = "Nowadays";

  const title = metadata.title || "";
  const description = metadata.description || "";

  const openGraphTitle = metadata.openGraphTitle || "";
  const openGraphDescription = metadata.openGraphDescription || "";
  const openGraphImage = metadata.openGraphImage
    ? metadata.openGraphImage.asset.url
    : "";

  const twitterTitle = metadata.twitterTitle || "";
  const twitterDescription = metadata.twitterDescription || "";
  const twitterImage = metadata.twitterImage
    ? metadata.twitterImage.asset.url
    : "";

  const url = `https://nowadayscreative.com${ path }`;

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <link rel="icon" type="image/png" href={favicon16Src} sizes="16x16"/>
        <link rel="icon" type="image/png" href={favicon32Src} sizes="32x32"/>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>

      {
        // If a title or a description is provided, override both those supplied
        // in the root template. This means we will always use the root template
        // defined meta title and description, but that if an individual page
        // sets, for example, just a new title, the description for that page
        // will update to be blank rather than using the root template's
        // description. The logic being: if an admin sets just a title, they
        // probably intend that there not be a description, but if they set
        // neither, that they probably intend for the page to just use default
        // values rather than nothing at all.
        (title || description) && (
          <Helmet>
            <title>{ title || defaultTitle }</title>
            <meta name="description" content={description}/>
          </Helmet>
        )
      }

      <Helmet>
        <meta property="og:locale" content="en_US"/>
        <meta
          property="og:url"
          content={url}
        />
        <meta property="og:site_name" content="Nowadays"/>
      </Helmet>

      {
        // Same logic here as with title and description above.
        (openGraphTitle || openGraphDescription) && (
          <Helmet>
            <meta
              property="og:title"
              content={openGraphTitle}
            />
            <meta
              property="og:description"
              content={openGraphDescription}
            />
          </Helmet>
        )
      }

      {
        openGraphImage && (
          <Helmet>
            <meta property="og:image" content={openGraphImage}/>
          </Helmet>
        )
      }

      <Helmet>
        <meta property="og:type" content="article"/>
        <meta
          name="twitter:url"
          content={url}
        />
        <meta name="twitter:card" content="summary_large_image"/>
      </Helmet>

      {
        // Same logic here as with title and description above.
        (twitterTitle || twitterDescription) && (
          <Helmet>
            <meta
              name="twitter:title"
              content={twitterTitle}
            />
            <meta
              name="twitter:description"
              content={twitterDescription}
            />
          </Helmet>
        )
      }

      {
        twitterImage && (
          <Helmet>
            <meta name="twitter:image:src" content={twitterImage}/>
          </Helmet>
        )
      }
    </React.Fragment>
  );
};

export default Metadata;
