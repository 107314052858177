import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledMobileMenuContactLink = styled.a.attrs(props => {
  const {
    className,
    focused
  } = props;

  return {
    className: classNames(className, "chrome__content-desk__mobile-menu-contact-link", {
      "chrome__content-desk__mobile-menu-contact-link--is-focused": focused
    }),
    tabIndex: 0
  };
})`
  ${({ theme: { borders, colors, fonts, fontWeights, radii } }) => `
    align-items: center;
    border-radius: ${ radii.circle };
    box-shadow: ${ borders.outer.highlight.full } ${ colors.white };
    color: ${ colors.white };
    cursor: pointer;
    display: inline-flex;
    font-family: ${ fonts.gtAmericaExpanded };
    font-size: 16px;
    font-weight: ${ fontWeights.normal };
    height: 40px;
    justify-content: center;
    letter-spacing: 3px;
    line-height: 40px;
    margin-top: auto;
    max-width: 200px;
    outline: none;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    width: 100%;

    &:before {
      background-color: ${ colors.white };
      border-radius: ${ radii.circle };
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 64ms cubic-bezier(.455, .03, .515, 1.0);
      will-change: opacity;
    }

    &:after {
      align-items: center;
      bottom: 0;
      // Use the data-text attribute passed to this element for the "button
      // text". This allows us to nicely utilize mix-blend-mode during hover or
      // focus transitions of the "background" (controlled by the :before
      // pseudo element).
      content: attr(data-text);
      color: ${ colors.white };
      display: inline-flex;
      justify-content: center;
      left: 0;
      mix-blend-mode: exclusion;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:active, &.chrome__content-desk__mobile-menu__close--is-active,
    &:hover, &.chrome__content-desk__mobile-menu__close--is-hovered
    {
      &:before {
        opacity: 1;
      }
    }

    // :focus managed by KeyboardFocusContainer
    &.chrome__content-desk__mobile-menu__close--is-focused
    {
      &:before {
        opacity: 1;
      }
    }
  `}
`;

const MobileMenuContactLink = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledMobileMenuContactLink
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

MobileMenuContactLink.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

MobileMenuContactLink.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

export default MobileMenuContactLink;
