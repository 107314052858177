import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledMobileMenuLink = styled(
  ({ active, focused, hovered, ...rest }) => <Link {...rest} />
).attrs(props => {
  const {
    className,
    active,
    focused,
    hovered
  } = props;

  return {
    className: classNames(className, "chrome__content-desk__mobile-menu-link", {
      "chrome__content-desk__mobile-menu-link--is-active": active,
      "chrome__content-desk__mobile-menu-link--is-focused": focused,
      "chrome__content-desk__mobile-menu-link--is-hovered": hovered
    }),
    tabIndex: 0
  };
})`
  ${ buttonResets }

  ${({ theme: { borders, colors, fonts, fontSizes, fontWeights, lineHeights } }) => `
    color: ${ colors.white };
    cursor: pointer;
    font-family: ${ fonts.gtAmericaExpanded };
    font-size: ${ fontSizes.xxLarge };
    font-weight: ${ fontWeights.normal };
    letter-spacing: 3px;
    line-height: 46px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;

    &:active, &.chrome__content-desk__mobile-menu-link--is-active,
    &:hover, &.chrome__content-desk__mobile-menu-link--is-hovered
    {
      text-decoration: underline;
    }

    // :focus managed by KeyboardFocusContainer
    &.chrome__content-desk__mobile-menu-link--is-focused
    {
      text-decoration: underline;
    }
  `}
`;

const MobileMenuLink = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledMobileMenuLink
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

MobileMenuLink.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

MobileMenuLink.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

export default MobileMenuLink;
