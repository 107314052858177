import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import isWindow from "dom-helpers/query/isWindow";
import ownerDocument from "dom-helpers/ownerDocument";
import ownerWindow from "dom-helpers/ownerWindow";
import css from "dom-helpers/style";
import getScrollbarSize from "dom-helpers/util/scrollbarSize";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";

import { globalHistory } from "@reach/router";

import { AppContext } from "@nowadays/Core";
import { isBrowser } from "@nowadays/Utils/Window";

import MobileMenuComponent from "./components/MobileMenu";
import MobileMenuPanel from "./components/MobileMenuPanel";
import MobileMenuWrapper from "./views/MobileMenuWrapper";

const MobileMenu = props => {
  const { transitionMs } = props;

  const {
    closeMobileMenu,
    mobileMenuOpen
  } = useContext(AppContext);

  const [mounted, setMounted] = useState(false);

  const lastOpenStateRef = useRef(mobileMenuOpen);
  const transitionMsRef = useRef(transitionMs);

  const previousBodyPaddingRightRef = useRef("");
  const previousBodyPositionRef = useRef("");
  const previousBodyLeftRef = useRef("");
  const previousBodyRightRef = useRef("");
  const previousBodyTopRef = useRef("");
  const previousHtmlScrollRef = useRef("");

  // Close mobile menu on route changes.
  useEffect(() => {
    if (!mobileMenuOpen) { return; }

    let menuCloseTimeout;

    const removeListener = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        menuCloseTimeout = setTimeout(closeMobileMenu, 50);
      }
    });

    return () => {
      removeListener();
      clearTimeout(menuCloseTimeout);
    };
  }, [mobileMenuOpen]);

  useEffect(() => {
    if (!isBrowser()) { return; }

    const lastOpenState = lastOpenStateRef.current;
    const transitionMs = transitionMsRef.current;

    let transitionTimeout;

    const bodyElement = document.querySelector("body");
    const htmlElement = document.querySelector("html");

    const isOverflowing = element => {
      const doc = ownerDocument(element);
      const win = ownerWindow(doc);

      const isBody = element && element.tagName.toLowerCase() === "body";

      if (!isWindow(doc) && !isBody) {
        return element.scrollHeight > element.clientHeight;
      }

      const style = win.getComputedStyle(doc.body);
      const marginLeft = parseInt(style.getPropertyValue("margin-left"), 10);
      const marginRight = parseInt(style.getPropertyValue("margin-right"), 10);

      return marginLeft + doc.body.clientWidth + marginRight < win.innerWidth;
    };

    const setMountStyles = () => {
      if (isOverflowing(bodyElement)) {
        const scrollbarSize = getScrollbarSize();
        const bodyPaddingRight =
          parseInt(css(bodyElement, "paddingRight") || 0, 10);

        previousBodyPaddingRightRef.current = bodyElement.style.paddingRight;
        bodyElement.style.paddingRight = `${bodyPaddingRight + scrollbarSize}px`;
      }

      previousHtmlScrollRef.current = htmlElement.scrollTop;

      previousBodyLeftRef.current = bodyElement.style.left;
      previousBodyPositionRef.current = bodyElement.style.position;
      previousBodyRightRef.current = bodyElement.style.right;
      previousBodyTopRef.current = bodyElement.style.top;

      bodyElement.style.left = "0";
      bodyElement.style.position = "fixed";
      bodyElement.style.right = "0";
      bodyElement.style.top = `-${previousHtmlScrollRef.current}px`;
    };

    const setUnmountStyles = () => {
      bodyElement.style.left = previousBodyLeftRef.current;
      bodyElement.style.paddingRight = previousBodyPaddingRightRef.current;
      bodyElement.style.position = previousBodyPositionRef.current;
      bodyElement.style.right = previousBodyRightRef.current;
      bodyElement.style.top = previousBodyTopRef.current;

      htmlElement.scrollTop = previousHtmlScrollRef.current;
    };

    const mount = () => {
      setMountStyles();
      setMounted(true);
    };

    const unmount = () => {
      setUnmountStyles();

      transitionTimeout = setTimeout(() => {
        setMounted(false);
      }, transitionMs);
    };

    if (lastOpenState !== mobileMenuOpen) {
      mobileMenuOpen ? mount() : unmount();
    }

    lastOpenStateRef.current = mobileMenuOpen;

    return () => {
      clearTimeout(transitionTimeout);
    };
  }, [mobileMenuOpen]);

  return useMemo(() => {
    if (!mounted) {
      return null;
    }

    return (
      <FocusLock
        disabled={!mobileMenuOpen}
        as={MobileMenuWrapper}
        autoFocus={false}
      >
        <MobileMenuPanel
          open={mobileMenuOpen}
          transitionMs={transitionMs}
        >
          <MobileMenuComponent transitionMs={transitionMs}/>
        </MobileMenuPanel>
      </FocusLock>
    );
  }, [mobileMenuOpen, mounted, transitionMs]);
};

MobileMenu.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

MobileMenu.defaultProps = {};

export default MobileMenu;
