import { useEffect, useState, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

import isBrowser from "../utils/isBrowser";

export const useOnWindowResize = (callback, { debounceMs=200 }={}) => {
  const [resizeCallbackTrigger, setResizeCallbackTrigger] = useState(null);

  const callbackRef = useRef(callback);

  useEffect(() => {
    if (!isBrowser()) { return; }

    // Listen to body.
    const bodyResizeObserver = new ResizeObserver(entries => {
      setResizeCallbackTrigger(+new Date());
    });
    bodyResizeObserver.observe(document.querySelector("body"));

    // Listen to window.
    const windowResizeListener = () => {
      setResizeCallbackTrigger(+new Date());
    };
    window.addEventListener("resize", windowResizeListener, { passive: true });

    return () => {
      window.removeEventListener("resize", windowResizeListener);
      bodyResizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!resizeCallbackTrigger) { return; }

    const callbackTimeout = setTimeout(callbackRef.current(), debounceMs);

    return () => {
      clearTimeout(callbackTimeout);
    };
  }, [debounceMs, resizeCallbackTrigger]);
};

export default useOnWindowResize;
