import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Logo as GenericLogo } from "@nowadays/UI/Logo";

const Logo = styled(GenericLogo).attrs(props => {
  const {
    className,
    collapsed
  } = props;

  return {
    className: classNames(className, "chrome__header-logo", {
      "chrome__header-logo--is-collapsed": collapsed
    })
  };
})`
  ${ ({ theme: { colors, space } }) => `
    color: ${ colors.white };
    height: 20px;
    padding-left: ${ space.small };
    vertical-align: top;
  `};

  ${media.sm`
    ${ () => `
      && {
        height: 40px;
        padding-left: 0;
      }
    `}
  `}
`;

export default Logo;
