import React, { useContext } from "react";

import { AppContext } from "@nowadays/Core";

import AboutPanel from "./components/AboutPanel";
import MainContent from "./components/MainContent";
import MobileMenu from "./components/MobileMenu";
import StyledContentDesk from "./views/ContentDesk";

const ContentDesk = props => {
  const { children } = props;

  const {
    aboutPanelOpen
  } = useContext(AppContext);

  return (
    <StyledContentDesk
      open={aboutPanelOpen}
      transitionMs={ContentDesk.TRANSITION_MS}
    >
      <AboutPanel transitionMs={ContentDesk.TRANSITION_MS}/>
      <MobileMenu transitionMs={ContentDesk.TRANSITION_MS}/>
      <MainContent>
        { children }
      </MainContent>
    </StyledContentDesk>
  );
}

ContentDesk.TRANSITION_MS = 320;

export default ContentDesk;
