import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { IconURL } from "@nowadays/UI/Icon";
import { svg } from "@nowadays/UI/Utils";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledClose = styled.button.attrs(props => {
  const {
    className,
    active,
    focused,
    hovered
  } = props;

  return {
    "aria-label": "Close Mobile Menu",
    className: classNames(className, "chrome__content-desk__mobile-menu__close", {
      "chrome__content-desk__mobile-menu__close--is-active": active,
      "chrome__content-desk__mobile-menu__close--is-focused": focused,
      "chrome__content-desk__mobile-menu__close--is-hovered": hovered
    })
  };
})`
  ${ buttonResets }

  ${({ theme: { borders, colors, chrome, space } }) => `
    background-color: ${ colors.black };
    // Combination gradient background-image and box-shadow to support
    // cross-browser subpixel borders. Be careful reconfiguring these values.
    background-image:
      linear-gradient(
        to top, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
      );
    background-origin: border-box;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    box-shadow: ${ borders.bottom } ${ colors.white };
    flex: 0 0 calc(${ chrome.mobile.headerHeight } + env(safe-area-inset-top, 0));
    height: calc(${ chrome.mobile.headerHeight } + env(safe-area-inset-top, 0));
    position: relative;
    vertical-align: top;
    width: 100%;

    &:after {
      background-image:
        url(${ svg.replaceColors(IconURL.X, { stroke: colors.white }) });
      background-origin: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: ${ space.xSmall };
      bottom: 0;
      content: "";
      left: 0;
      mix-blend-mode: exclusion;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome, colors } }) => `
      && {
        flex: 0 0 calc(${ chrome.headerHeight } + env(safe-area-inset-top, 0));
        height: calc(${ chrome.headerHeight } + env(safe-area-inset-top, 0));

        &:before {
          background-color: ${ colors.white };
          bottom: 0;
          content: "";
          left: 0;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
          transition: opacity 64ms cubic-bezier(.455, .03, .515, 1.0);
          will-change: opacity;
        }

        &:active, &.chrome__content-desk__mobile-menu__close--is-active,
        &:hover, &.chrome__content-desk__mobile-menu__close--is-hovered
        {
          &:before {
            opacity: 1;
          }
        }

        // :focus managed by KeyboardFocusContainer
        &.chrome__content-desk__mobile-menu__close--is-focused
        {
          &:before {
            opacity: 1;
          }
        }
      }
    `}
  `}
`;

const Close = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledClose
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Close.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

Close.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

export default Close;
