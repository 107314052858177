/*
 * Chrome Specifications
 *
 */
const chrome = {};

chrome.headerHeight = "90px";
chrome.footerHeight = "50px";
chrome.aboutPanelWidth = "calc(50 * var(--vw))";

chrome.mobile = {};
chrome.mobile.headerHeight = "60px";
chrome.mobile.footerHeight = "30px";
chrome.mobile.aboutPanelWidth = "calc(100 * var(--vw))";

export default chrome;
