import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import { useCompanyData } from "@nowadays/Graph/hooks";

import StyledMainMenu from "./views/MainMenu";
import MainMenuWrapper from "./views/MainMenuWrapper";
import MobileMenuItems from "./views/MobileMenuItems";
import MobileMenuContactLink from "./views/MobileMenuContactLink";
import MobileMenuLink from "./views/MobileMenuLink";

const MainMenu = props => {
  const { onAboutMenuLinkClicked, open, transitionMs } = props;

  const { email } = useCompanyData();

  const [entered, setEntered] = useState(open);
  const [mounted, setMounted] = useState(open);

  const lastOpenStateRef = useRef(open);
  const transitionMsRef = useRef(transitionMs);

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;
    const transitionMs = transitionMsRef.current;

    let toggleTimeout;

    const closeMenu = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
      }, transitionMs);
    };

    const openMenu = () => {
      setMounted(true);

      toggleTimeout = setTimeout(() => {
        setEntered(true);
      }, transitionMs);
    };

    if (lastOpenState !== open) {
      open ? openMenu() : closeMenu();
    }

    lastOpenStateRef.current = open;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [open]);

  const handleHomeMenuLinkClicked = () => {
    navigate("/");
  };

  const handleProjectsMenuLinkClicked = () => {
    navigate("/projects");
  };

  if (!mounted) {
    return null;
  }

  return (
    <StyledMainMenu
      entered={entered}
      open={open}
      transitionMs={transitionMs}
    >
      <MobileMenuItems>
        <MobileMenuLink
          as="button"
          onClick={handleHomeMenuLinkClicked}
        >
          Home
        </MobileMenuLink>
        <MobileMenuLink
          as="button"
          onClick={handleProjectsMenuLinkClicked}
        >
          Projects
        </MobileMenuLink>
        <MobileMenuLink
          as="button"
          onClick={onAboutMenuLinkClicked}
        >
          About
        </MobileMenuLink>
        <MobileMenuContactLink
          href={`mailto:${email}`}
          title="contact"
          data-text="Say Hi"
        />
      </MobileMenuItems>
    </StyledMainMenu>
  );
};

MainMenu.propTypes = {
  onAboutMenuLinkClicked: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

MainMenu.defaultProps = {};

export default MainMenu;

