import React, { useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "@nowadays/Core";

import MainMenu from "./components/MainMenu";
import AboutMenu from "./components/AboutMenu";
import Close from "./views/Close";
import StyledMobileMenu from "./views/MobileMenu";

const MobileMenu = props => {
  const { transitionMs } = props;

  const menuSwapTransitionMs = transitionMs / 2;

  const {
    closeMobileMenu,
    mobileMenuOpen
  } = useContext(AppContext);

  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [menuSwapTrigger, setMenuSwapTrigger] = useState(null);

  const menuSwapTransitionMsRef = useRef(menuSwapTransitionMs);

  useEffect(() => {
    if (mobileMenuOpen) {
      setShowMainMenu(true);
    } else {
      setShowAboutMenu(false);
      setShowMainMenu(false);
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    if (!menuSwapTrigger) { return; }

    const swapToAboutMenu = () => {
      setShowAboutMenu(true);
    };

    setShowMainMenu(false);

    const menuSwapTimeout =
      setTimeout(swapToAboutMenu, menuSwapTransitionMsRef.current);

    return () => {
      clearTimeout(menuSwapTimeout);
    };
  }, [menuSwapTrigger]);

  const handleAboutMenuLinkClicked = () => {
    setMenuSwapTrigger(+new Date());
  };

  return (
    <StyledMobileMenu>
      <Close onClick={closeMobileMenu}/>
      <MainMenu
        onAboutMenuLinkClicked={handleAboutMenuLinkClicked}
        open={showMainMenu}
        transitionMs={menuSwapTransitionMs}
      />
      <AboutMenu
        open={showAboutMenu}
        transitionMs={menuSwapTransitionMs}
      />
    </StyledMobileMenu>
  );
};

export default MobileMenu;
