import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Header = styled.header.attrs(props => {
  const {
    className,
    collapsed
  } = props;

  return {
    className: classNames(className, "chrome__header", {
      "chrome__header--is-collapsed": collapsed
    })
  }
})`
  ${ ({ theme: { borders, chrome, colors, lineHeights, space } }) => `
    align-items: center;
    // Combination gradient background-image and box-shadow to support
    // cross-browser subpixel borders. Be careful reconfiguring these values.
    background-image:
      linear-gradient(
        to top, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
      );
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    box-shadow: ${ borders.bottom } ${ colors.white };
    display: flex;
    height: calc(${ chrome.mobile.headerHeight } + env(safe-area-inset-top, 0));
    // If Nowadays ever wants exclusion turned back on, find all instances of
    // this comment and uncomment the mix-blend-mode line.
    // mix-blend-mode: exclusion;
    padding: env(safe-area-inset-top, 0) ${ space.xxSmall };
    position: sticky;
    top: 0;
    z-index: 999;
  `};

  ${media.sm`
    ${ ({ theme: { chrome, space } }) => `
      && {
        height: calc(${ chrome.headerHeight } + env(safe-area-inset-top, 0));
        padding:
          calc(
            env(safe-area-inset-top, 0) + ${ space.xxSmall }
          )
          ${ space.xLarge }
          0;
      }
    `}
  `}
`;

export default Header;
