import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Aspirations = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__aspirations")
  };
})`
  ${({ theme: { space } }) => `
    padding-bottom: ${ space.medium };
    position: relative;
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      padding-bottom: ${ space.large };
    `}
  `}
`;

Aspirations.propTypes = {};

Aspirations.defaultProps = {};

export default Aspirations;
