import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

export const SPACINGS = {
  NORMAL: "normal",
  SMALL: "small",
  LARGE: "large"
};

export const WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold"
};

const Text = styled.div.attrs(
  props => {
    const {
      expanded,
      noHighlight,
      spacing,
      truncate,
      uppercase,
      weight
    } = props;

    return {
      className: classNames("text", {
          "text--expanded": expanded,
          "text--no-highlight": noHighlight,
          "text--truncated": truncate,
          "text--uppercase": uppercase,

          "text--spacing-small": spacing === SPACINGS.SMALL,
          "text--spacing-large": spacing === SPACINGS.LARGE,

          "text--weight-bold": weight === WEIGHTS.BOLD,
        })
    };
  }
)`
  ${({ theme: { colors, fonts, fontWeights } }) => `
    font: inherit;
    font-family: ${ fonts.gtAmerica };
    font-weight: ${ fontWeights.normal };
    letter-spacing: normal;

    &.text--expanded {
      font-family: ${ fonts.gtAmericaExpanded };
    }

    &.text--no-highlight {
      user-select: none;
    }

    &.text--truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.text--uppercase {
      text-transform: uppercase;
    }

    &.text--spacing-small {
      letter-spacing: 1px;
    }

    &.text--spacing-large {
      letter-spacing: 3px;
    }

    &.text--weight-bold {
      font-weight: ${ fontWeights.bold };
    }
  `}
`;

Text.propTypes = {
  expanded: PropTypes.bool,
  noHighlight: PropTypes.bool,
  truncate: PropTypes.bool,
  uppercase: PropTypes.bool,
  spacing: PropTypes.oneOf(
    [
      SPACINGS.NORMAL,
      SPACINGS.SMALL,
      SPACINGS.LARGE
    ]
  ),
  weight: PropTypes.oneOf(
    [
      WEIGHTS.NORMAL,
      WEIGHTS.BOLD
    ]
  )
};

Text.defaultProps = {
  expanded: false,
  noHighlight: false,
  truncate: false,
  uppercase: false,
  spacing: SPACINGS.NORMAL,
  weight: WEIGHTS.NORMAL
};

export default Text;
