import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const MobileMenuItems = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__mobile-menu-items"
    )
  };
})`
  ${({ theme: { chrome, space } }) => `
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: ${ chrome.mobile.footerHeight };
    padding: ${ space.small };
  `};

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        margin-bottom: ${ chrome.footerHeight };
      }
    `}
  `}
`;

MobileMenuItems.propTypes = {};

MobileMenuItems.defaultProps = {};

export default MobileMenuItems;
