import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Footer = styled.footer.attrs(props => {
  const {
    className,
    aboutPanelOpen
  } = props;

  return {
    className: classNames(className, "chrome__footer", {
      "chrome__footer--about-panel-open": aboutPanelOpen
    })
  }
})`
  ${
    (
      {
        bodyPaddingRight, theme: {
          borders, chrome, colors, lineHeights, space
        }
      }
    ) => `
      align-items: center;
      // Combination gradient background-image and box-shadow to support
      // cross-browser subpixel borders. Be careful reconfiguring these values.
      background-image: linear-gradient(to bottom, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%);
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      box-shadow: ${ borders.top } ${ colors.white };
      bottom: 0;
      display: flex;
      height: ${ chrome.mobile.footerHeight };
      left: 0;
      // If Nowadays ever wants exclusion turned back on, find all instances of
      // this comment and uncomment the mix-blend-mode line.
      // mix-blend-mode: exclusion;
      padding: 0 ${ space.medium };
      position: fixed;
      right: 0;
      text-transform: uppercase;

      ${ bodyPaddingRight
        ? `padding-right: calc(${ bodyPaddingRight } + ${ space.medium });`
        : ""
      }
    `};

  ${media.sm`
    ${ ({ bodyPaddingRight, theme: { chrome, space } }) => `
      && {
        height: ${ chrome.footerHeight };
        left: ${ space.xxSmall };
        padding: ${ space.xxSmall } ${ space.large };
        right: ${ space.xxSmall };

        ${ bodyPaddingRight
          ? `padding-right: calc(${ bodyPaddingRight } + ${ space.large });`
          : ""
        }

        &.chrome__footer--about-panel-open {
          padding-left: ${ space.xLarge };
          left: 0;
        }
      }
    `}
  `}
`;

Footer.defaultProps = {};

export default Footer;
