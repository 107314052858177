/*
 * Font Sizes
 *
 */
const fontSizes = [
  "6px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "24px",
  "36px",
  "48px"
];

// Aliases
fontSizes.copy = fontSizes[4];

fontSizes.xxxSmall = fontSizes[0];
fontSizes.xxSmall = fontSizes[1];
fontSizes.xSmall = fontSizes[2];
fontSizes.small = fontSizes[3];
fontSizes.medium = fontSizes[4];
fontSizes.large = fontSizes[5];
fontSizes.xLarge = fontSizes[6];
fontSizes.xxLarge = fontSizes[7];
fontSizes.xxxLarge = fontSizes[8];

export default fontSizes;
