/*
 * Colors
 *
 */
const black = "#000000";
const white = "#FFFFFF";
const transparent = "transparent";

const colors = {
  copy: white,

  black: black,
  white: white,
  transparent: transparent
};

export default colors;
