import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XXXLargeParagraphStyles = css`
  ${({ theme: { fontSizes, lineHeights, measures }}) => `
    font-size: ${ fontSizes.xxxLarge };
    line-height: ${ lineHeights.xxxLarge };

    & + & {
      margin-top: ${ lineHeights.xxxLarge };
    }

    &.paragraph--with-measure {
      max-width: ${ measures.xxxLarge };
      width: 100%;
    }
  `}
`;

const XXXLargeParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__xxx-large-paragraph")
    };
  }
)`
  ${ XXXLargeParagraphStyles }
`;

XXXLargeParagraph.propTypes = {
  ...Paragraph.propTypes
};

XXXLargeParagraph.defaultProps = {};

export default XXXLargeParagraph;
