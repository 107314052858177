import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XXXLargeTextStyles = css`
  ${({ theme: { fontSizes, lineHeights }}) => `
    font-size: ${ fontSizes.xxxLarge };
    line-height: ${ lineHeights.xxxLarge };
  `}
`;

const XXXLargeText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__xxx-large-text")
    };
  }
)`
  ${ XXXLargeTextStyles }
`;

XXXLargeText.propTypes = {
  ...Text.propTypes
};

XXXLargeText.defaultProps = {};

export default XXXLargeText;
