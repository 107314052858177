/*
 * Borders
 *
 * All sizes in REM.
 *
 */
const borders = {};

// 0.03125rem = ~0.5px
borders.full = "inset 0 0 0 0.03125rem";
borders.bottom = "inset 0 -0.03125rem 0 0";
borders.left = "inset 0.03125rem 0 0 0";
borders.right = "inset -0.03125rem 0 0 0";
borders.top = "inset 0 0.03125rem 0 0";

// 0.078125rem = ~1.25px
borders.highlight = {};
borders.highlight.full = "inset 0 0 0 0.078125rem";
borders.highlight.bottom = "inset 0 -0.078125rem 0 0";
borders.highlight.left = "inset 0.078125rem 0 0 0";
borders.highlight.right = "inset -0.078125rem 0 0 0";
borders.highlight.top = "inset 0 0.078125rem 0 0";

// 0.03125rem = ~0.5px
borders.outer = {};
borders.outer.full = "0 0 0 0.03125rem";
borders.outer.bottom = "0 0.03125rem 0 0";
borders.outer.left = "-0.03125rem 0 0 0";
borders.outer.right = "0.03125rem 0 0 0";
borders.outer.top = "0 -0.03125rem 0 0";

// 0.078125rem = ~1.25px
borders.outer.highlight = {};
borders.outer.highlight.full = "0 0 0 0.078125rem";
borders.outer.highlight.bottom = "0 0.078125rem 0 0";
borders.outer.highlight.left = "-0.078125rem 0 0 0";
borders.outer.highlight.right = "0.078125rem 0 0 0";
borders.outer.highlight.top = "0 -0.078125rem 0 0";

export default borders;
