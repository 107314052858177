/*
 * Space
 *
 */
const space = [
  "0px",
  "5px",
  "10px",
  "15px",
  "20px",
  "30px",
  "40px",
  "50px",
  "60px"
];

// Aliases
space.xxxSmall = space[1];
space.xxSmall = space[2];
space.xSmall = space[3];
space.small = space[4];
space.medium = space[5];
space.large = space[6];
space.xLarge = space[7];
space.xxLarge = space[8];

export default space;
