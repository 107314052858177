import PropTypes from "prop-types";
import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { LargeTextStyles } from "./LargeText";
import { MediumTextStyles } from "./MediumText";
import { SmallTextStyles } from "./SmallText";
import { XLargeTextStyles } from "./XLargeText";
import { XSmallTextStyles } from "./XSmallText";
import { XXLargeTextStyles } from "./XXLargeText";
import { XXSmallTextStyles } from "./XXSmallText";
import { XXXLargeTextStyles } from "./XXXLargeText";
import { XXXSmallTextStyles } from "./XXXSmallText";

import Text from "./Text";

const SIZES = {
  XXX_SMALL: "xxxSmall",
  XX_SMALL: "xxSmall",
  X_SMALL: "xSmall",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  X_LARGE: "xLarge",
  XX_LARGE: "xxLarge",
  XXX_LARGE: "xxxLarge"
};

const ResponsiveText = styled(Text).attrs(
  props => {
    const {
      className,
      xxl,
      xl,
      lg,
      md,
      sm,
      xs
    } = props;

    return {
      className: classNames(className, "text__responsive-text", {
        "text__responsive-text--xxl--xxx-small-text": xxl === SIZES.XXX_SMALL,
        "text__responsive-text--xxl--xx-small-text": xxl === SIZES.XX_SMALL,
        "text__responsive-text--xxl--x-small-text": xxl === SIZES.X_SMALL,
        "text__responsive-text--xxl--small-text": xxl === SIZES.SMALL,
        "text__responsive-text--xxl--medium-text": xxl === SIZES.MEDIUM,
        "text__responsive-text--xxl--large-text": xxl === SIZES.LARGE,
        "text__responsive-text--xxl--x-large-text": xxl === SIZES.X_LARGE,
        "text__responsive-text--xxl--xx-large-text": xxl === SIZES.XX_LARGE,
        "text__responsive-text--xxl--xxx-large-text": xxl === SIZES.XXX_LARGE,

        "text__responsive-text--xl--xxx-small-text": xl === SIZES.XXX_SMALL,
        "text__responsive-text--xl--xx-small-text": xl === SIZES.XX_SMALL,
        "text__responsive-text--xl--x-small-text": xl === SIZES.X_SMALL,
        "text__responsive-text--xl--small-text": xl === SIZES.SMALL,
        "text__responsive-text--xl--medium-text": xl === SIZES.MEDIUM,
        "text__responsive-text--xl--large-text": xl === SIZES.LARGE,
        "text__responsive-text--xl--x-large-text": xl === SIZES.X_LARGE,
        "text__responsive-text--xl--xx-large-text": xl === SIZES.XX_LARGE,
        "text__responsive-text--xl--xxx-large-text": xl === SIZES.XXX_LARGE,

        "text__responsive-text--lg--xxx-small-text": lg === SIZES.XXX_SMALL,
        "text__responsive-text--lg--xx-small-text": lg === SIZES.XX_SMALL,
        "text__responsive-text--lg--x-small-text": lg === SIZES.X_SMALL,
        "text__responsive-text--lg--small-text": lg === SIZES.SMALL,
        "text__responsive-text--lg--medium-text": lg === SIZES.MEDIUM,
        "text__responsive-text--lg--large-text": lg === SIZES.LARGE,
        "text__responsive-text--lg--x-large-text": lg === SIZES.X_LARGE,
        "text__responsive-text--lg--xx-large-text": lg === SIZES.XX_LARGE,
        "text__responsive-text--lg--xxx-large-text": lg === SIZES.XXX_LARGE,

        "text__responsive-text--md--xxx-small-text": md === SIZES.XXX_SMALL,
        "text__responsive-text--md--xx-small-text": md === SIZES.XX_SMALL,
        "text__responsive-text--md--x-small-text": md === SIZES.X_SMALL,
        "text__responsive-text--md--small-text": md === SIZES.SMALL,
        "text__responsive-text--md--medium-text": md === SIZES.MEDIUM,
        "text__responsive-text--md--large-text": md === SIZES.LARGE,
        "text__responsive-text--md--x-large-text": md === SIZES.X_LARGE,
        "text__responsive-text--md--xx-large-text": md === SIZES.XX_LARGE,
        "text__responsive-text--md--xxx-large-text": md === SIZES.XXX_LARGE,

        "text__responsive-text--sm--xxx-small-text": sm === SIZES.XXX_SMALL,
        "text__responsive-text--sm--xx-small-text": sm === SIZES.XX_SMALL,
        "text__responsive-text--sm--x-small-text": sm === SIZES.X_SMALL,
        "text__responsive-text--sm--small-text": sm === SIZES.SMALL,
        "text__responsive-text--sm--medium-text": sm === SIZES.MEDIUM,
        "text__responsive-text--sm--large-text": sm === SIZES.LARGE,
        "text__responsive-text--sm--x-large-text": sm === SIZES.X_LARGE,
        "text__responsive-text--sm--xx-large-text": sm === SIZES.XX_LARGE,
        "text__responsive-text--sm--xxx-large-text": sm === SIZES.XXX_LARGE,

        "text__responsive-text--xs--xxx-small-text": xs === SIZES.XXX_SMALL,
        "text__responsive-text--xs--xx-small-text": xs === SIZES.XX_SMALL,
        "text__responsive-text--xs--x-small-text": xs === SIZES.X_SMALL,
        "text__responsive-text--xs--small-text": xs === SIZES.SMALL,
        "text__responsive-text--xs--medium-text": xs === SIZES.MEDIUM,
        "text__responsive-text--xs--large-text": xs === SIZES.LARGE,
        "text__responsive-text--xs--x-large-text": xs === SIZES.X_LARGE,
        "text__responsive-text--xs--xx-large-text": xs === SIZES.XX_LARGE,
        "text__responsive-text--xs--xxx-large-text": xs === SIZES.XXX_LARGE,
      })
    };
  }
)`
  ${media.xs`
    &.text__responsive-text--xs--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--xs--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--xs--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--xs--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--xs--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--xs--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--xs--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--xs--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--xs--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}

  ${media.sm`
    &.text__responsive-text--sm--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--sm--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--sm--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--sm--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--sm--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--sm--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--sm--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--sm--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--sm--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}

  ${media.md`
    &.text__responsive-text--md--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--md--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--md--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--md--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--md--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--md--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--md--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--md--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--md--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}

  ${media.lg`
    &.text__responsive-text--lg--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--lg--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--lg--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--lg--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--lg--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--lg--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--lg--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--lg--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--lg--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}

  ${media.xl`
    &.text__responsive-text--xl--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--xl--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--xl--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--xl--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--xl--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--xl--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--xl--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--xl--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--xl--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}

  ${media.xxl`
    &.text__responsive-text--xxl--xxx-small-text {
      ${ XXXSmallTextStyles }
    }

    &.text__responsive-text--xxl--xx-small-text {
      ${ XXSmallTextStyles }
    }

    &.text__responsive-text--xxl--x-small-text {
      ${ XSmallTextStyles }
    }

    &.text__responsive-text--xxl--small-text {
      ${ SmallTextStyles }
    }

    &.text__responsive-text--xxl--medium-text {
      ${ MediumTextStyles }
    }

    &.text__responsive-text--xxl--large-text {
      ${ LargeTextStyles }
    }

    &.text__responsive-text--xxl--x-large-text {
      ${ XLargeTextStyles }
    }

    &.text__responsive-text--xxl--xx-large-text {
      ${ XXLargeTextStyles }
    }

    &.text__responsive-text--xxl--xxx-large-text {
      ${ XXXLargeTextStyles }
    }
  `}
`;

ResponsiveText.propTypes = {
  xxl: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  xl: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  lg: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  md: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  sm: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  xs: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ])
};

ResponsiveText.defaultProps = {};

export default ResponsiveText;
