import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledNavLink = styled(
  ({ active, focused, hovered, ...rest }) => <Link {...rest} />
).attrs(props => {
  const {
    className,
    active,
    focused,
    hovered
  } = props;

  return {
    className: classNames(className, "chrome__header-nav-link", {
      "chrome__header-nav-link--is-active": active,
      "chrome__header-nav-link--is-focused": focused,
      "chrome__header-nav-link--is-hovered": hovered
    }),
    tabIndex: 0
  };
})`
  ${ buttonResets }

  ${({ theme: { borders, colors, fontSizes, fontWeights, lineHeights, space } }) => `
    color: ${ colors.white };
    cursor: pointer;
    font-size: ${ fontSizes.small };
    font-weight: ${ fontWeights.normal };
    height: calc(${ fontSizes.small } + ${ space.xxxSmall });
    letter-spacing: 3px;
    line-height: ${ lineHeights.small };
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;

    // Note:
    // We use the :after pseudo selector instead of applying box-shadow
    // directly to the button itself due to the fact that browser have a bug
    // where texts with letter-spacing applied include the letter-spacing
    // _after_ the last character, and not just between characters, artificially
    // adding padding after the text in the browser. This leads to underlines,
    // shadows, etc looking wider than they should. Note the width calculation.
    &:after {
      bottom: 0;
      box-shadow: none;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: calc(100% - 3px);
    }

    &:active, &.chrome__header-nav-link--is-active,
    &:hover, &.chrome__header-nav-link--is-hovered
    {
      &:after {
        box-shadow: ${ borders.outer.highlight.bottom } ${ colors.white };
      }
    }

    // :focus managed by KeyboardFocusContainer
    &.chrome__header-nav-link--is-focused
    {
      &:after {
        box-shadow: ${ borders.outer.highlight.bottom } ${ colors.white };
      }
    }
  `}
`;

const NavLink = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledNavLink
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

NavLink.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

NavLink.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

export default NavLink;
