import { useStaticQuery, graphql } from "gatsby";

const GET_COMPANY = graphql`
  query CompanyData {
    sanityCompany {
      ...fieldsOnCompany
    }
  }
`;

export const useCompanyData = () => {
  const { sanityCompany: company } = useStaticQuery(GET_COMPANY);

  return company;
};

export default useCompanyData;
