import classNames from "classnames";
import styled from "styled-components";

const InstagramLink = styled.a.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__instagram-link")
  };
})`
  ${({ theme: { space } }) => `
    text-decoration: none;
  `}
`;

export default InstagramLink;
