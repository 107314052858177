import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Close = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill="none"
      stroke={color}
      strokeLinecap="square"
      strokeWidth="2"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
        <line x1="2" y1="2" x2="16.1421356" y2="16.1421356"></line>
        <line x1="16.1421356" y1="2" x2="2" y2="16.1421356"></line>
    </svg>
  );
});

Close.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Close.defaultProps = {
  color: "currentColor"
}

export default Close;
