import { useStaticQuery, graphql } from "gatsby";

const GET_HOME_PAGE = graphql`
  query HomePageData {
    sanityHomePage {
      ...fieldsOnHomePage
    }
  }
`;

export const useHomePageData = () => {
  const { sanityHomePage: homePage } = useStaticQuery(GET_HOME_PAGE);

  return homePage;
};

export default useHomePageData;
