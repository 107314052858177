import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XXSmallTextStyles = css`
  ${({ theme: { fontSizes, lineHeights }}) => `
    font-size: ${ fontSizes.xxSmall };
    line-height: ${ lineHeights.xxSmall };
  `}
`;

const XXSmallText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__xx-small-text")
    };
  }
)`
  ${ XXSmallTextStyles }
`;

XXSmallText.propTypes = {
  ...Text.propTypes
};

XXSmallText.defaultProps = {};

export default XXSmallText;
