import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const TRANSITION_NAME = `chrome__content-desk__mobile-menu-panel`;

const StyledMobileMenuContent = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "chrome__content-desk__mobile-menu-panel"
      ),
      tabIndex: "-1"
    };
  }
)`
  ${({ theme: { chrome, colors, space }, transitionMs }) => `
    background: ${ colors.black };
    height: calc(100 * var(--vh));
    min-width: 0;
    opacity: 0.0;
    outline: none;
    pointer-events: none;
    position: sticky;
    top: 0;
    transform: translateX(100%);
    transition:
      opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0),
      transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    width: calc(100 * var(--vw));
    will-change: opacity, transform;
    z-index: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0.01;
      pointer-events: none;
      transform: translateX(50%);
    };

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active {
      opacity: 1;
      pointer-events: none;
      transform: translateX(0%);
    };

    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0%);
    };

    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
      pointer-events: none;
      transform: translateX(0%);
    };

    // Careful with these && declarations. These will override mobile settings
    // and other settings that follow here.
    && {
      &.${ TRANSITION_NAME }-exit-active {
        opacity: 0.01;
        pointer-events: none;
        transform: translateX(50%);
      };

      &.${ TRANSITION_NAME }-exit-done {
        opacity: 0.0;
        pointer-events: none;
        transform: translateX(100%);
      };
    }
  `}
`;

StyledMobileMenuContent.propTypes = {};

StyledMobileMenuContent.defaultProps = {};

const MobileMenuContent = props => {
  const { children, open, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={open}
      timeout={{
        appear: transitionMs,
        enter: transitionMs,
        exit: transitionMs
      }}
    >
      <StyledMobileMenuContent
        transitionMs={transitionMs}
        {...passdownProps}
      >
        { children }
      </StyledMobileMenuContent>
    </CSSTransition>
  );
};

MobileMenuContent.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

MobileMenuContent.defaultProps = {};

export default MobileMenuContent;
