import React from "react";

import { useCompanyData } from "@nowadays/Graph/hooks";

import { Box } from "@nowadays/UI/Box";
import { XSmallText } from "@nowadays/UI/Typography";

import Title from "./views/Title";

const Skills = props => {
  const { services } = useCompanyData();

  return (
    <React.Fragment>
      <Title>
        What we do
      </Title>
      <Box maxWidth="200px">
        <XSmallText>
          {
            services.map((service, i) => [
              i > 0 && ", ",
              <span key={`about__service-k-${ i }`}>{ service }</span>
            ])
          }
        </XSmallText>
      </Box>
    </React.Fragment>
  );
};

export default Skills;
