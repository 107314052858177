import React from "react";

import { useCompanyData } from "@nowadays/Graph/hooks";
import { Anchor } from "@nowadays/UI/Button";
import { XLargeText, XXSmallText } from "@nowadays/UI/Typography";

import ContactDesktop from "./views/ContactDesktop";
import ContactMobile from "./views/ContactMobile";

const Contact = props => {
  const { email } = useCompanyData();

  return (
    <div>
      <ContactDesktop>
        <XLargeText expanded truncate>
          <Anchor forceUnderline href={`mailto:${email}`} title="contact">
            { email }
          </Anchor>
        </XLargeText>
      </ContactDesktop>
      <ContactMobile>
        <XXSmallText expanded truncate uppercase>
          <Anchor
            forceUnderline
            href={`mailto:${email}`}
            title="contact"
          >
            { email }
          </Anchor>
        </XXSmallText>
      </ContactMobile>
    </div>
  );
};

export default Contact;
