import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Logo = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill={color}
      stroke="none"
      viewBox="0 0 420 99"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="58.18 1.01 58.18 74.17 49.37 74.17 9.27 14.69 10.1 74.17 0.98 74.17 0.98 1.01 10.1 1.01 49.79 59.66 49.38 1.01"></polygon>
      <path d="M88.74,75.62 C71.85,75.62 64.7,62.98 64.7,50.13 C64.7,39.25 70.09,23.81 89.05,23.81 C105.22,23.81 112.68,36.35 112.57,49.72 C112.47,64.53 103.66,75.62 88.74,75.62 Z M103.04,42.87 C101.17,33.23 94.85,30.44 89.26,30.44 C78.17,30.44 73.72,39.04 73.72,50.23 C73.72,60.49 77.97,68.99 88.64,68.99 C102.42,68.99 103.46,54.69 103.56,49.72 C103.66,46.6 103.35,44.32 103.04,42.87 Z"></path>
      <polygon points="153.4 25.36 165 61.63 177.75 25.36 186.45 25.36 168.52 74.17 161.27 74.17 149.56 37.8 137.64 74.17 130.08 74.17 113.09 25.36 122 25.36 134.23 61.63 146.25 25.36"></polygon>
      <path d="M218.86,44.94 L218.86,43.7 C218.86,38.62 218.86,30.13 207.88,30.13 C197.41,30.13 197.41,37.8 197.41,40.39 L188.71,39.87 C188.92,38.11 189.12,35.41 190.68,32.51 C193.58,27.12 199.28,23.81 207.57,23.81 C212.13,23.81 218.04,24.95 221.87,27.95 C227.26,32.3 227.26,37.9 227.16,44.43 L227.16,60.28 C227.26,64.94 227.26,67.33 230.68,67.33 C231.72,67.33 232.65,67.12 233.37,66.81 L233.37,73.96 C232.02,74.27 230.57,74.79 228.19,74.79 C224.36,74.79 220.52,73.55 219.69,67.54 C217.2,70.13 212.23,75.52 202.9,75.52 C193.37,75.52 186.32,69.82 186.32,61.53 C186.32,58.94 187.05,56.35 188.5,54.17 C191.3,50.02 196.48,46.71 211.09,45.57 L218.86,44.94 Z M206.12,52.51 C197,54.48 195.45,57.9 195.45,61.32 C195.45,65.26 198.56,68.57 204.67,68.57 C210.37,68.57 215.24,66.29 217.62,61.21 C218.86,58.52 218.97,57.38 218.97,50.23 C215.75,50.75 211.4,51.37 206.12,52.51 Z"></path>
      <path d="M282.19,0.9 L282.19,74.16 L273.9,74.16 L273.9,67.84 C271.93,70.22 267.37,75.61 257.11,75.61 C243.02,75.61 234.73,65.04 234.73,49.5 C234.73,30.43 247.16,23.8 257.01,23.8 C260.22,23.8 268.3,24.53 273.9,31.78 L273.9,0.9 L282.19,0.9 Z M243.54,49.81 C243.54,59.86 248.2,68.25 258.77,68.25 C268.2,68.25 274.11,61.2 274.11,49.49 C274.11,35.71 265.72,31.36 259.08,31.25 C252.76,31.16 243.54,35 243.54,49.81 Z"></path>
      <path d="M321.25,44.94 L321.25,43.7 C321.25,38.62 321.25,30.13 310.27,30.13 C299.8,30.13 299.8,37.8 299.8,40.39 L291.1,39.87 C291.31,38.11 291.51,35.41 293.07,32.51 C295.97,27.12 301.67,23.81 309.96,23.81 C314.52,23.81 320.43,24.95 324.26,27.95 C329.65,32.3 329.65,37.9 329.54,44.43 L329.54,60.28 C329.64,64.94 329.64,67.33 333.06,67.33 C334.1,67.33 335.03,67.12 335.75,66.81 L335.75,73.96 C334.4,74.27 332.95,74.79 330.57,74.79 C326.74,74.79 322.9,73.55 322.07,67.54 C319.58,70.13 314.61,75.52 305.28,75.52 C295.75,75.52 288.7,69.82 288.7,61.53 C288.7,58.94 289.43,56.35 290.88,54.17 C293.68,50.02 298.86,46.71 313.47,45.57 L321.25,44.94 Z M308.51,52.51 C299.39,54.48 297.84,57.9 297.84,61.32 C297.84,65.26 300.95,68.57 307.06,68.57 C312.76,68.57 317.63,66.29 320.01,61.21 C321.25,58.52 321.36,57.38 321.36,50.23 C318.14,50.75 313.79,51.37 308.51,52.51 Z"></path>
      <path d="M375.86,25.36 L355.34,83.08 C351.82,92.92 349.43,98.31 340.31,98.31 C336.99,98.31 334.51,97.69 332.75,97.17 L332.75,89.81 C333.89,90.12 335.65,90.64 338.04,90.64 C344.05,90.64 345.4,87.12 347.78,80.38 L349.85,74.47 L333.27,25.35 L342.08,25.35 L354.31,64.1 L367.26,25.35 L375.86,25.35 L375.86,25.36 Z"></path>
      <path d="M396.27,52.61 C388.39,50.75 378.76,48.57 378.76,38.41 C378.76,29.5 386.32,23.9 397.62,23.9 C406.95,23.9 416.89,28.36 418.55,39.86 L409.64,41 C409.54,38 409.33,35.61 406.12,33.12 C402.91,30.63 399.18,30.22 396.69,30.22 C390.27,30.22 387.05,34.05 387.05,37.37 C387.05,42.03 392.34,43.48 398.76,45.04 L403.42,46.18 C409.22,47.53 419.69,50.01 419.69,60.27 C419.69,68.25 412.64,75.71 399.17,75.71 C393.68,75.71 387.98,74.57 384.04,71.77 C382.49,70.63 377.72,66.69 376.79,58.51 L386.12,57.06 C386.02,59.24 386.02,63.38 389.75,66.59 C392.65,69.08 396.28,69.39 399.59,69.39 C406.84,69.39 411.09,66.59 411.09,61.31 C411.09,55.71 406.84,54.68 400.93,53.54 L396.27,52.61 Z"></path>
    </svg>
  );
});

Logo.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Logo.defaultProps = {
  color: "currentColor"
}

export default Logo;
