import classNames from "classnames";
import styled from "styled-components";

const Track = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__scroll-area__scrollbars__track")
  };
})`
  ${({ theme: { baseUnit, borders, colors } }) => `
    background-color: ${ colors.black };
    border-radius: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
    );
    background-position: left;
    background-repeat: no-repeat;
    background-size: 1px 100%;
    box-shadow: ${ borders.left } ${ colors.white };
    display: flex;
    justify-content: center;
    padding: 2px 0;
    position: absolute;
    right: 0;
    top: 0;
    width: ${ baseUnit } !important;
  `}
`;

Track.propTypes = {};

Track.defaultProps = {};

export default Track;
