import React, { useContext } from "react";

import { AppContext } from "@nowadays/Core";
import { useCompanyData } from "@nowadays/Graph/hooks";

import Address from "./views/Address";
import FooterComponent from "./components/Footer";
import FooterItem from "./views/FooterItem";
import InstagramLink from "./views/InstagramLink";

const Footer = props => {
  const { aboutPanelOpen } = useContext(AppContext);
  const { address, instagramUrl } = useCompanyData();

  return (
    <FooterComponent aboutPanelOpen={aboutPanelOpen}>
      <FooterItem align="start">
        <Address>
          { address }
        </Address>
      </FooterItem>
      <FooterItem align="end">
        <InstagramLink
          href={instagramUrl}
          rel="noopener noreferrer"
          target="_blank"
        />
      </FooterItem>
    </FooterComponent>
  );
}

export default Footer;
