import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XXXSmallTextStyles = css`
  ${({ theme: { fontSizes, lineHeights }}) => `
    font-size: ${ fontSizes.xxxSmall };
    line-height: ${ lineHeights.xxxSmall };
  `}
`;

const XXXSmallText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__xxx-small-text")
    };
  }
)`
  ${ XXXSmallTextStyles }
`;

XXXSmallText.propTypes = {
  ...Text.propTypes
};

XXXSmallText.defaultProps = {};

export default XXXSmallText;
