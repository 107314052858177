import classNames from "classnames";
import styled from "styled-components";

const CarouselWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__aspirations__carousel-wrapper")
  };
})`
  overflow: hidden;
`;

CarouselWrapper.propTypes = {};

CarouselWrapper.defaultProps = {};

export default CarouselWrapper;
