import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XXSmallParagraphStyles = css`
  ${({ theme: { fontSizes, lineHeights, measures }}) => `
    font-size: ${ fontSizes.xxSmall };
    line-height: ${ lineHeights.xxSmall };

    & + & {
      margin-top: ${ lineHeights.xxSmall };
    }

    &.paragraph--with-measure {
      max-width: ${ measures.xxSmall };
      width: 100%;
    }
  `}
`;

const XXSmallParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__xx-small-paragraph")
    };
  }
)`
  ${ XXSmallParagraphStyles }
`;

XXSmallParagraph.propTypes = {
  ...Paragraph.propTypes
};

XXSmallParagraph.defaultProps = {};

export default XXSmallParagraph;
