import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const LeftArrow = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeLinecap="square"
      strokeWidth="1"
      viewBox="0 0 31 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M14.6313143,0.388163223 L15.6118368,2.1313143 L14.7402612,2.62157554 L6.067,7.5 L31,7.5 L31,9.5 L6.067,9.5 L14.7402612,14.3784245 L15.6118368,14.8686857 L14.6313143,16.6118368 L13.7597388,16.1215755 L1.75973876,9.37157554 L0.210271139,8.5 L1.75973876,7.62842446 L13.7597388,0.878424463 L14.6313143,0.388163223 Z"></path>
      </g>
    </svg>
  );
});

LeftArrow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LeftArrow.defaultProps = {
  color: "currentColor"
}

export default LeftArrow;
