import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TRANSITION_NAME = `chrome__content-desk__about-panel`;

const StyledAboutContent = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "chrome__content-desk__about-panel"),
      tabIndex: "-1"
    };
  }
)`
  ${({ theme: { chrome, colors }, transitionMs }) => `
    background: ${ colors.black };
    display: flex;
    flex-direction: column;
    height: calc((100 * var(--vh)) - ${ chrome.mobile.footerHeight });
    opacity: 0.0;
    outline: none;
    pointer-events: none;
    position: sticky;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    width: 100%;
    will-change: opacity;
    z-index: 1002;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0.01;
    };

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active {
      opacity: 1;
    };

    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done {
      opacity: 1;
      pointer-events: all;
    };

    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
    };

    // Careful with these && declarations. These will override mobile settings
    // and other settings that follow here.
    && {
      &.${ TRANSITION_NAME }-exit-active {
        opacity: 0.01;
      };

      &.${ TRANSITION_NAME }-exit-done {
        opacity: 0.0;
      };
    }
  `}

  ${media.sm`
    ${ ({ theme: { borders, chrome, colors } }) => `
      && {
        height: calc((100 * var(--vh)) - ${ chrome.footerHeight });

        // Combination gradient background-image and box-shadow to support
        // cross-browser subpixel borders. Be careful reconfiguring these
        // values. Also note that we have to use pseudo elements to (one to
        // these right side, and one at the bottom, both offset a pixel to sit
        // outside of this element, in order to position our "borders" - which
        // are in some browsers the background-image - on the _outside_ like a
        // box-shadow).
        &:before {
          background-image: linear-gradient(
            to right, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
          );
          background-position: left;
          background-repeat: no-repeat;
          background-size: 1px 100%;
          box-shadow: ${ borders.left } ${ colors.white };
          bottom: 0;
          content: "";
          pointer-events: none;
          position: absolute;
          right: -1px;
          top: 0;
          width: 1px;
          z-index: 1;
        }
      }
    `}
  `}
`;

StyledAboutContent.propTypes = {};

StyledAboutContent.defaultProps = {};

const AboutContent = props => {
  const { children, open, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={open}
      timeout={{
        appear: transitionMs, enter: transitionMs, exit: transitionMs
      }}
    >
      <StyledAboutContent
        transitionMs={transitionMs}
        {...passdownProps}
      >
        { children }
      </StyledAboutContent>
    </CSSTransition>
  );
};

AboutContent.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

AboutContent.defaultProps = {};

export default AboutContent;
