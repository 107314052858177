import classNames from "classnames";
import styled from "styled-components";

const Carousel = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__aspirations__carousel")
  };
})`
  display: flex;
`;

Carousel.propTypes = {};

Carousel.defaultProps = {};

export default Carousel;
