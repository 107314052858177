import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const XXLargeTextStyles = css`
  ${({ theme: { fontSizes, lineHeights }}) => `
    font-size: ${ fontSizes.xxLarge };
    line-height: ${ lineHeights.xxLarge };
  `}
`;

const XXLargeText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__xx-large-text")
    };
  }
)`
  ${ XXLargeTextStyles }
`;

XXLargeText.propTypes = {
  ...Text.propTypes
};

XXLargeText.defaultProps = {};

export default XXLargeText;
