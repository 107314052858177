import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const RightArrow = forwardRef((props, ref) => {
  const {
    className,
    color,
    height,
    width
  } = props;

  return (
    <svg
      ref={ref}
      className={className}
      height={height}
      width={width}
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeLinecap="square"
      strokeWidth="1"
      viewBox="0 0 31 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M16.3686857,0.388163223 L17.2402612,0.878424463 L29.2402612,7.62842446 L30.7897289,8.5 L29.2402612,9.37157554 L17.2402612,16.1215755 L16.3686857,16.6118368 L15.3881632,14.8686857 L16.2597388,14.3784245 L24.932,9.5 L1.42108547e-14,9.5 L1.42108547e-14,7.5 L24.932,7.5 L16.2597388,2.62157554 L15.3881632,2.1313143 L16.3686857,0.388163223 Z"></path>
      </g>
    </svg>
  );
});

RightArrow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RightArrow.defaultProps = {
  color: "currentColor"
}

export default RightArrow;
