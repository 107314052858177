import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { Text } from "@nowadays/UI/Typography"

const Address = styled(Text).attrs(props => {
  const {
    className
  } = props;

  return {
    as: "span",
    className: classNames(className, "chrome__footer__address")
  };
})`
  ${ ({ theme: { fontSizes, lineHeights } }) => `
    font-size: ${ fontSizes.xxxSmall };
    font-weight: normal;
    letter-spacing: 0.64px;
    line-height: ${ lineHeights.xxxSmall };
  `};

  ${media.sm`
    ${ ({ theme: { fontSizes, lineHeights } }) => `
      && {
        font-size: ${ fontSizes.xxSmall };
        font-weight: normal;
        letter-spacing: 1.33px;
        line-height: ${ lineHeights.xxSmall };
      }
    `}
  `}
`;

Address.defaultProps = {
  expanded: true,
  uppercase: true
};

export default Address;
