import React from "react";

import { AppContext } from "@nowadays/Core";

import useToggleAboutPanel from "./hooks/useToggleAboutPanel";
import useToggleMobileMenu from "./hooks/useToggleMobileMenu";

const AppSystem = props => {
  const {
    children
  } = props;

  const [
    {
      aboutPanelOpen
    },
    closeAboutPanel,
    openAboutPanel
  ] = useToggleAboutPanel();

  const [
    {
      mobileMenuOpen
    },
    closeMobileMenu,
    openMobileMenu
  ] = useToggleMobileMenu();

  const system = {
    aboutPanelOpen: aboutPanelOpen,
    closeAboutPanel: closeAboutPanel,
    openAboutPanel: openAboutPanel,
    mobileMenuOpen: mobileMenuOpen,
    closeMobileMenu: closeMobileMenu,
    openMobileMenu: openMobileMenu
  };

  return (
    <AppContext.Provider value={system}>
      { children }
    </AppContext.Provider>
  );
};

export default AppSystem;
