import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const TRANSITION_NAME = `chrome__content-desk__mobile-menu__main-menu`;

const StyledMainMenu = styled.div.attrs(
  props => {
    const {
      className,
      entered
    } = props;

    return {
      className: classNames(
        className,
        "chrome__content-desk__mobile-menu__main-menu"
      ),
      tabIndex: "-1"
    };
  }
)`
  ${({ theme: { chrome, colors }, transitionMs }) => `
    background: ${ colors.black };
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 0.0;
    outline: none;
    pointer-events: none;
    transition: opacity ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    width: 100%;
    will-change: opacity;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      opacity: 0.01;
      pointer-events: none;
    };

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active {
      opacity: 1;
      pointer-events: none;
    };

    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done {
      opacity: 1;
      pointer-events: all;
    };

    &.${ TRANSITION_NAME }-exit {
      opacity: 1;
      pointer-events: none;
    };

    // Careful with these && declarations. These will override mobile settings
    // and other settings that follow here.
    && {
      &.${ TRANSITION_NAME }-exit-active {
        opacity: 0.01;
        pointer-events: none;
      };

      &.${ TRANSITION_NAME }-exit-done {
        opacity: 0.0;
        pointer-events: none;
      };
    }
  `}
`;

StyledMainMenu.propTypes = {};

StyledMainMenu.defaultProps = {};

const MainMenu = props => {
  const { children, open, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={open}
      timeout={{
        appear: transitionMs,
        enter: transitionMs,
        exit: transitionMs
      }}
    >
      <StyledMainMenu
        transitionMs={transitionMs}
        {...passdownProps}
      >
        { children }
      </StyledMainMenu>
    </CSSTransition>
  );
};

MainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

MainMenu.defaultProps = {};

export default MainMenu;
