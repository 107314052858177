import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const ContactMobile = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__contact-mobile")
  };
})`
  ${({ theme: { space } }) => `
    display: flex;
    justify-content: center;
    margin: ${ space.xxSmall } 0;
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      && {
        display: none;
      }
    `}
  `}
`;

ContactMobile.propTypes = {};

ContactMobile.defaultProps = {};

export default ContactMobile;
