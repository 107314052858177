import React from "react";
import ReactDOMServer from "react-dom/server";

import { svg } from "@nowadays/UI/Utils";

import Instagram from "../icons/Instagram";
import LeftArrow from "../icons/LeftArrow";
import Plus from "../icons/Plus";
import RightArrow from "../icons/RightArrow";
import X from "../icons/X";

const allIcons = {
  Instagram,
  LeftArrow,
  Plus,
  RightArrow,
  X
};

/*
 * IconURL
 *
 * The IconURL object wraps icons provided by components to return the icons as
 * simple data URLs. The IconURL object is not a component itself, and should
 * not be used directly. Rather, icons should be referenced by name off this
 * object.
 *
 * Example, for our "X" icon:
 *
 * // Don't do this:
 * const x = X;
 *
 * // Do this:
 * const x = IconURL.X;
 *
 * Note:
 * If you need to access additional icons not currently in the allIcons object,
 * define and import its component and add it to the allIcons object defined
 * above.
 *
 */
const IconURL = Object.entries(allIcons)
  .reduce((memo, [name, IconComponent]) => {
    const RenderedIcon = ReactDOMServer.renderToString(
      <IconComponent/>
    );

    memo[name] = svg.toDataURL(RenderedIcon);

    return memo;
  }, {});

export default IconURL;
