import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XSmallParagraphStyles = css`
  ${({ theme: { fontSizes, lineHeights, measures }}) => `
    font-size: ${ fontSizes.xSmall };
    line-height: ${ lineHeights.xSmall };

    & + & {
      margin-top: ${ lineHeights.xSmall };
    }

    &.paragraph--with-measure {
      max-width: ${ measures.xSmall };
      width: 100%;
    }
  `}
`;

const XSmallParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__x-small-paragraph")
    };
  }
)`
  ${ XSmallParagraphStyles }
`;

XSmallParagraph.propTypes = {
  ...Paragraph.propTypes
};

XSmallParagraph.defaultProps = {};

export default XSmallParagraph;
