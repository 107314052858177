import React from "react";
import Img from "gatsby-image";

import { useCompanyData } from "@nowadays/Graph/hooks";

import InstagramLink from "./views/InstagramLink";

const Instagram = props => {
  const { instagramImage, instagramUrl, name } = useCompanyData();

  const hotspot = instagramImage.hotspot || {};

  const {
    x=0.5,
    y=0.5
  } = hotspot;

  const fluid = instagramImage.asset.fluid;

  return (
    <InstagramLink
      href={instagramUrl}
      rel="noopener noreferrer"
      target="_blank"
      title={`${ name } Instagram`}
    >
      <Img
        alt={`${ name } Instagram`}
        fadeIn={false}
        fluid={fluid}
        loading="eager"
        objectFit="cover"
        objectPosition={`${ x * 100 }% ${ y * 100 }%`}
        style={
          {
            height: "100%",
            position: "relative",
            width: "100%",
            willChange: "transform"
          }
        }
        placeholderStyle={
          {
            filter: "blur(20px)"
          }
        }
      />
    </InstagramLink>
  );
};

export default Instagram;
