import classNames from "classnames";
import styled from "styled-components";

const AboutMenuInnerWrapper = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__mobile-menu__about-menu-inner-wrapper"
    )
  };
})`
  ${({ theme: { chrome, space } }) => `
    height: 100%;
    width: 100%;
  `};
`;

AboutMenuInnerWrapper.propTypes = {};

AboutMenuInnerWrapper.defaultProps = {};

export default AboutMenuInnerWrapper;
