import { useState } from "react";

const useToggleAboutPanel = () => {

  const [aboutPanelOpen, setAboutPanelOpen] = useState(false);

  const closeAboutPanel = () => {
    setAboutPanelOpen(false);
  };

  const openAboutPanel = () => {
    setAboutPanelOpen(true);
  };

  return [
    {
      aboutPanelOpen
    },
    closeAboutPanel,
    openAboutPanel
  ];
};

export default useToggleAboutPanel;
