import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const AboutPanelWrapper = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__about-panel-wrapper"
    )
  };
})`
  ${({ theme: { chrome } }) => `
    flex: 0 0 ${ chrome.mobile.aboutPanelWidth };
    min-width: 0;
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        flex: 0 0 ${ chrome.aboutPanelWidth };
      }
    `}
  `}
`;

AboutPanelWrapper.propTypes = {};

AboutPanelWrapper.defaultProps = {};

export default AboutPanelWrapper;
