import React, { useContext } from "react";
import { Link } from "gatsby";
//import { Link } from "@reach/router";

import { AppContext } from "@nowadays/Core";

import StyledHeader from "./views/Header";
import Logo from "./views/Logo";
import NavItem from "./views/NavItem";
import NavLink from "./views/NavLink";
import NavMobileMenuLink from "./views/NavMobileMenuLink";

const Header = props => {
  const {
    aboutPanelOpen,
    openAboutPanel,
    openMobileMenu
  } = useContext(AppContext);

  return (
    <StyledHeader>
      <NavItem align="start" hiddenSmDown>
        <NavLink as="button" active={aboutPanelOpen} onClick={openAboutPanel}>
          About
        </NavLink>
      </NavItem>
      <NavItem align="center" hiddenSmDown>
        <Link
          to="/"
        >
          <Logo/>
        </Link>
      </NavItem>
      <NavItem align="end" hiddenSmDown>
        <NavLink
          to="/projects"
          activeClassName="chrome__header-nav-link--is-active"
        >
          Projects
        </NavLink>
      </NavItem>
      <NavItem align="start" hiddenSmUp>
        <Link
          to="/"
        >
          <Logo/>
        </Link>
      </NavItem>
      <NavItem align="end" hiddenSmUp>
        <NavMobileMenuLink onClick={openMobileMenu}/>
      </NavItem>
    </StyledHeader>
  );
}

export default Header;
