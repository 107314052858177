import PropTypes from "prop-types";
import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { LargeParagraphStyles } from "./LargeParagraph";
import { MediumParagraphStyles } from "./MediumParagraph";
import { SmallParagraphStyles } from "./SmallParagraph";
import { XLargeParagraphStyles } from "./XLargeParagraph";
import { XSmallParagraphStyles } from "./XSmallParagraph";
import { XXLargeParagraphStyles } from "./XXLargeParagraph";
import { XXSmallParagraphStyles } from "./XXSmallParagraph";
import { XXXLargeParagraphStyles } from "./XXXLargeParagraph";
import { XXXSmallParagraphStyles } from "./XXXSmallParagraph";

import Paragraph from "./Paragraph";

const SIZES = {
  XXX_SMALL: "xxxSmall",
  XX_SMALL: "xxSmall",
  X_SMALL: "xSmall",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  X_LARGE: "xLarge",
  XX_LARGE: "xxLarge",
  XXX_LARGE: "xxxLarge"
};

const ResponsiveParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className,
      xxl,
      xl,
      lg,
      md,
      sm,
      xs
    } = props;

    return {
      className: classNames(className, "paragraph__responsive-paragraph", {
        "paragraph__responsive-paragraph--xxl--xxx-small-text": xxl === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--xxl--xx-small-text": xxl === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--xxl--x-small-text": xxl === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--xxl--small-text": xxl === SIZES.SMALL,
        "paragraph__responsive-paragraph--xxl--medium-text": xxl === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--xxl--large-text": xxl === SIZES.LARGE,
        "paragraph__responsive-paragraph--xxl--x-large-text": xxl === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--xxl--xx-large-text": xxl === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--xxl--xxx-large-text": xxl === SIZES.XX_LARGE,

        "paragraph__responsive-paragraph--xl--xxx-small-text": xl === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--xl--xx-small-text": xl === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--xl--x-small-text": xl === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--xl--small-text": xl === SIZES.SMALL,
        "paragraph__responsive-paragraph--xl--medium-text": xl === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--xl--large-text": xl === SIZES.LARGE,
        "paragraph__responsive-paragraph--xl--x-large-text": xl === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--xl--xx-large-text": xl === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--xl--xxx-large-text": xl === SIZES.XX_LARGE,

        "paragraph__responsive-paragraph--lg--xxx-small-text": lg === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--lg--xx-small-text": lg === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--lg--x-small-text": lg === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--lg--small-text": lg === SIZES.SMALL,
        "paragraph__responsive-paragraph--lg--medium-text": lg === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--lg--large-text": lg === SIZES.LARGE,
        "paragraph__responsive-paragraph--lg--x-large-text": lg === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--lg--xx-large-text": lg === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--lg--xxx-large-text": lg === SIZES.XX_LARGE,

        "paragraph__responsive-paragraph--md--xxx-small-text": md === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--md--xx-small-text": md === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--md--x-small-text": md === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--md--small-text": md === SIZES.SMALL,
        "paragraph__responsive-paragraph--md--medium-text": md === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--md--large-text": md === SIZES.LARGE,
        "paragraph__responsive-paragraph--md--x-large-text": md === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--md--xx-large-text": md === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--md--xxx-large-text": md === SIZES.XX_LARGE,

        "paragraph__responsive-paragraph--sm--xxx-small-text": sm === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--sm--xx-small-text": sm === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--sm--x-small-text": sm === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--sm--small-text": sm === SIZES.SMALL,
        "paragraph__responsive-paragraph--sm--medium-text": sm === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--sm--large-text": sm === SIZES.LARGE,
        "paragraph__responsive-paragraph--sm--x-large-text": sm === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--sm--xx-large-text": sm === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--sm--xxx-large-text": sm === SIZES.XX_LARGE,

        "paragraph__responsive-paragraph--xs--xxx-small-text": xs === SIZES.XXX_SMALL,
        "paragraph__responsive-paragraph--xs--xx-small-text": xs === SIZES.XX_SMALL,
        "paragraph__responsive-paragraph--xs--x-small-text": xs === SIZES.X_SMALL,
        "paragraph__responsive-paragraph--xs--small-text": xs === SIZES.SMALL,
        "paragraph__responsive-paragraph--xs--medium-text": xs === SIZES.MEDIUM,
        "paragraph__responsive-paragraph--xs--large-text": xs === SIZES.LARGE,
        "paragraph__responsive-paragraph--xs--x-large-text": xs === SIZES.X_LARGE,
        "paragraph__responsive-paragraph--xs--xx-large-text": xs === SIZES.XX_LARGE,
        "paragraph__responsive-paragraph--xs--xxx-large-text": xs === SIZES.XX_LARGE
      })
    };
  }
)`
  ${media.xs`
    &.paragraph__responsive-paragraph--xs--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xs--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}

  ${media.sm`
    &.paragraph__responsive-paragraph--sm--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--sm--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}

  ${media.md`
    &.paragraph__responsive-paragraph--md--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--md--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}

  ${media.lg`
    &.paragraph__responsive-paragraph--lg--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--lg--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}

  ${media.xl`
    &.paragraph__responsive-paragraph--xl--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xl--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}

  ${media.xxl`
    &.paragraph__responsive-paragraph--xxl--xxx-small-text {
      ${ XXXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--xx-small-text {
      ${ XXSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--x-small-text {
      ${ XSmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--small-text {
      ${ SmallParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--medium-text {
      ${ MediumParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--large-text {
      ${ LargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--x-large-text {
      ${ XLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--xx-large-text {
      ${ XXLargeParagraphStyles }
    }

    &.paragraph__responsive-paragraph--xxl--xxx-large-text {
      ${ XXXLargeParagraphStyles }
    }
  `}
`;

ResponsiveParagraph.propTypes = {
  xxl: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  xl: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  lg: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  md: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  sm: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ]),
  xs: PropTypes.oneOf([
    SIZES.XXX_SMALL,
    SIZES.XX_SMALL,
    SIZES.X_SMALL,
    SIZES.SMALL,
    SIZES.MEDIUM,
    SIZES.LARGE,
    SIZES.X_LARGE,
    SIZES.XX_LARGE,
    SIZES.XXX_LARGE
  ])
};

ResponsiveParagraph.defaultProps = {};

export default ResponsiveParagraph;
