import classNames from "classnames";
import styled, { css } from "styled-components";

import Text from "./Text";

export const MediumTextStyles = css`
  ${({ theme: { fontSizes, lineHeights }}) => `
    font-size: ${ fontSizes.medium };
    line-height: ${ lineHeights.medium };
  `}
`;

const MediumText = styled(Text).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "text__medium-text")
    };
  }
)`
  ${ MediumTextStyles }
`;

MediumText.propTypes = {
  ...Text.propTypes
};

MediumText.defaultProps = {};

export default MediumText;
