import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const MainContentPanel = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__main-content-panel"
    )
  };
})`
  ${({ theme: { chrome } }) => `
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    min-height: calc(100 * var(--vh));
    min-width: 0;
    padding-bottom: ${ chrome.mobile.footerHeight };
    position: relative;
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        padding-bottom: ${ chrome.footerHeight };
      }
    `}
  `}
`;

MainContentPanel.defaultProps = {};

export default MainContentPanel;
