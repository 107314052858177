import React from "react";

import StyledFooter from "../views/Footer";

/*
 * Note on `bodyPaddingRight` hack.
 *
 * Within some of our components (e.g.; the About Panel component), we sometimes
 * update styles on the `body` tag in order to account for the style concerns of
 * a given component (such as setting the `body` to `overflow: hidden` whenever
 * the About Panel is open to prevent body scrolling).
 *
 * In these situations we sometimes need to account for the scrollbar on the
 * page and to adjust the `padding-right` on the page in order to account for
 * changes to the page width when removing the scrollbar so as not cause page
 * contents to pop around. However, because the Footer component is
 * `position: static`, we additionally need to account for these padding changes
 * on the Footer. We determined that here was the best place to listen and
 * account for these changes in inline styles on the `body`, as this isn't the
 * concern of those components themselves. If we ever choose to remove static
 * Footer styling, we can remove this code.
 *
 */
class Footer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      bodyPaddingRight: 0
    };
  }

  componentDidMount() {
    // There is no reference to the `document` within the server during Server
    // Side Rendering. Therefore we much check for the presence of the `window`
    // before attempting to work with it.
    if (typeof window !== "undefined") {
      this.setState({
        bodyPaddingRight: this.getBodyPaddingRight()
      }, this.listenForBodyStyleChanges);
    }
  }

  componentWillUnmount() {
    this.stopListeningForBodyStyleChanges();
  }

  listenForBodyStyleChanges = () => {
    const bodyElement = document.querySelector("body");

    this.observer = new MutationObserver(mutations => {
      this.setState({
        bodyPaddingRight: this.getBodyPaddingRight()
      });
    });

    this.observer.observe(bodyElement, {
      attributeFilter: ["style"],
      attributes: true
    });
  };

  stopListeningForBodyStyleChanges = () => {
    this.observer.disconnect();
  };

  getBodyPaddingRight = () => {
    const bodyElement = document.querySelector("body");
    const bodyPaddingRight = bodyElement.style.paddingRight;

    return bodyPaddingRight;
  };

  render() {
    const {
      children,
      ...passdownProps
    } = this.props;

    const {
      bodyPaddingRight
    } = this.state;

    return (
      <StyledFooter
        bodyPaddingRight={bodyPaddingRight}
        {...passdownProps}
      >
        { children }
      </StyledFooter>
    );
  }
}

export default Footer;
