import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { XXSmallText } from "@nowadays/UI/Typography";

const Title = styled(XXSmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__overview__title")
  };
})`
  ${() => `
    margin-bottom: 8px;
  `}

  ${media.sm`
    ${({ theme: { space } }) => `
      margin-bottom: ${ space.xxSmall };
    `}
  `}
`;

Title.propTypes = {};

Title.defaultProps = {
  expanded: true,
  noHighlight: true,
  spacing: "large",
  uppercase: true
};

export default Title;
