import React from "react";

import StyledMobileMenuPanel from "./views/MobileMenuPanel";

const MobileMenuPanel = props => {
  const { ...mobileMenuPanelProps } = props;

  return (
    <StyledMobileMenuPanel { ...mobileMenuPanelProps }/>
  );
};

export default MobileMenuPanel;
