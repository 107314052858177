import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

import { IconURL } from "@nowadays/UI/Icon";
import { svg } from "@nowadays/UI/Utils";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledNavMobileMenuLink = styled.button.attrs(
  props => {
    const {
      active,
      focused,
      hovered
    } = props;

    return {
      "aria-label": "Open Mobile Menu",
      className: classNames("chrome__header-nav-mobile-menu-link", {
        "chrome__header-nav-mobile-menu-link--is-active": active,
        "chrome__header-nav-mobile-menu-link--is-focused": focused,
        "chrome__header-nav-mobile-menu-link--is-hovered": hovered
      })
    };
  }
)`
  ${ buttonResets }

  ${ ({ theme: { chrome, colors, radii, space } }) => `
    box-sizing: border-box;
    height: calc(${ chrome.mobile.headerHeight } + env(safe-area-inset-top, 0));
    padding: ${ space.small };
    position: absolute;
    right: 0;
    top: 0;
    width: calc(${ chrome.mobile.headerHeight } + ${ space.small });

    &:after {
      background-image:
        url(${ svg.replaceColors(IconURL.Plus, { stroke: colors.white }) });
      background-origin: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      bottom: 0;
      content: "";
      left: 0;
      // If Nowadays ever wants exclusion turned back on, find all instances of
      // this comment and uncomment the mix-blend-mode line.
      // mix-blend-mode: exclusion;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  `}

  ${media.sm`
    ${ ({ theme: { colors } }) => `
      &:before {
        background-color: ${ colors.white };
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 64ms cubic-bezier(.455, .03, .515, 1.0);
        will-change: opacity;
      }

      &:active, &.chrome__header-nav-mobile-menu-link--is-active,
      &:hover, &.chrome__header-nav-mobile-menu-link--is-hovered
      {
        &:before {
          opacity: 1;
        }
      }

      // :focus managed by KeyboardFocusContainer
      &.chrome__header-nav-mobile-menu-link--is-focused
      {
        &:before {
          opacity: 1;
        }
      }
    `}
  `}
`;

const NavMobileMenuLink = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledNavMobileMenuLink
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

NavMobileMenuLink.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool
};

NavMobileMenuLink.defaultProps = {
  active: false,
  focused: false,
  hovered: false
};

export default NavMobileMenuLink;
