import classNames from "classnames";
import styled from "styled-components";

const MobileMenuWrapper = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__mobile-menu-wrapper"
    )
  };
})`
  ${() => `
    min-width: 0;
  `}
`;

MobileMenuWrapper.propTypes = {};

MobileMenuWrapper.defaultProps = {};

export default MobileMenuWrapper;
