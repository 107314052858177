import classNames from "classnames";
import styled from "styled-components";

const Thumb = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__scroll-area__scrollbars__thumb")
  };
})`
  ${({ theme: { colors, radii } }) => `
    background: ${ colors.white };
    box-shadow: none;
    border-radius: ${ radii.pill };
    cursor: pointer;
    width: 4px !important;
  `}
`;

Thumb.propTypes = {};

Thumb.defaultProps = {};

export default Thumb;
