import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";

import { AppContext } from "@nowadays/Core";

import About from
  "@nowadays/Layouts/App/components/ContentDesk/components/About";

import Backdrop from "./components/Backdrop";
import StyledAboutPanel from "./views/AboutPanel";
import AboutPanelWrapper from "./views/AboutPanelWrapper";
import Close from "./views/Close";

const AboutPanel = props => {
  const { transitionMs } = props;

  const {
    aboutPanelOpen,
    closeAboutPanel
  } = useContext(AppContext);

  const [entered, setEntered] = useState(aboutPanelOpen);
  const [mounted, setMounted] = useState(aboutPanelOpen);

  const lastOpenStateRef = useRef(aboutPanelOpen);
  const transitionMsRef = useRef(transitionMs);

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;
    const transitionMs = transitionMsRef.current;

    let toggleTimeout;

    const closeAboutPanel = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
      }, transitionMs);
    };

    const openAboutPanel = () => {
      setMounted(true);

      toggleTimeout = setTimeout(() => {
        setEntered(true);
      }, transitionMs);
    };

    if (lastOpenState !== aboutPanelOpen) {
      aboutPanelOpen ? openAboutPanel() : closeAboutPanel();
    }

    lastOpenStateRef.current = aboutPanelOpen;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [aboutPanelOpen]);

  return useMemo(() => {
    if (!mounted) {
      return null;
    }

    return (
      <FocusLock
        as={AboutPanelWrapper}
        autoFocus={false}
      >
        <Backdrop
          open={aboutPanelOpen}
          onClick={closeAboutPanel}
        />
        <StyledAboutPanel
          open={aboutPanelOpen}
          transitionMs={transitionMs}
        >
          <Close onClick={closeAboutPanel}/>
          <About/>
        </StyledAboutPanel>
      </FocusLock>
    );
  },
  [
    AboutPanelWrapper,
    aboutPanelOpen,
    closeAboutPanel,
    mounted,
    transitionMs
  ]);
};

AboutPanel.propTypes = {
  transitionMs: PropTypes.number.isRequired
};

AboutPanel.defaultProps = {};

export default AboutPanel;
