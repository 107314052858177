import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import About from
  "@nowadays/Layouts/App/components/ContentDesk/components/About";

import StyledAboutMenu from "./views/AboutMenu";
import AboutMenuInnerWrapper from "./views/AboutMenuInnerWrapper";

const AboutMenu = props => {
  const { open, transitionMs } = props;

  const [entered, setEntered] = useState(open);
  const [mounted, setMounted] = useState(open);

  const lastOpenStateRef = useRef(open);
  const transitionMsRef = useRef(transitionMs);

  useEffect(() => {
    const lastOpenState = lastOpenStateRef.current;
    const transitionMs = transitionMsRef.current;

    let toggleTimeout;

    const closeMenu = () => {
      setEntered(false);

      toggleTimeout = setTimeout(() => {
        setMounted(false);
      }, transitionMs);
    };

    const openMenu = () => {
      setMounted(true);

      toggleTimeout = setTimeout(() => {
        setEntered(true);
      }, transitionMs);
    };

    if (lastOpenState !== open) {
      open ? openMenu() : closeMenu();
    }

    lastOpenStateRef.current = open;

    return () => {
      clearTimeout(toggleTimeout);
    };
  }, [open]);

  if (!mounted) {
    return null;
  }

  return (
    <StyledAboutMenu
      entered={entered}
      open={open}
      transitionMs={transitionMs}
    >
      <AboutMenuInnerWrapper>
        <About/>
      </AboutMenuInnerWrapper>
    </StyledAboutMenu>
  );
};

AboutMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

AboutMenu.defaultProps = {};

export default AboutMenu;

