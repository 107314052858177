/*
 * Line Heights
 *
 */
const lineHeights = [
  "8px",
  "13px",
  "15px",
  "18px",
  "19px",
  "23px",
  "30px",
  "40px",
  "50px"
];

// Aliases
lineHeights.copy = lineHeights[4];

lineHeights.xxxSmall = lineHeights[0];
lineHeights.xxSmall = lineHeights[1];
lineHeights.xSmall = lineHeights[2];
lineHeights.small = lineHeights[3];
lineHeights.medium = lineHeights[4];
lineHeights.large = lineHeights[5];
lineHeights.xLarge = lineHeights[6];
lineHeights.xxLarge = lineHeights[7];
lineHeights.xxxLarge = lineHeights[8];

export default lineHeights;
