/*
 * Font Sizes
 *
 */
const measures = [
  "215px",
  "360px",
  "430px",
  "500px",
  "575px",
  "650px",
  "861px",
  "1295px",
  "1725px"
];

// Aliases
measures.copy = measures[4];

measures.xxxSmall = measures[0];
measures.xxSmall = measures[1];
measures.xSmall = measures[2];
measures.small = measures[3];
measures.medium = measures[4];
measures.large = measures[5];
measures.xLarge = measures[6];
measures.xxLarge = measures[7];
measures.xxxLarge = measures[8];

export default measures;
