import { useState } from "react";

const useToggleMobileMenu = () => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  return [
    {
      mobileMenuOpen
    },
    closeMobileMenu,
    openMobileMenu
  ];
};

export default useToggleMobileMenu;
