import React from "react";

import StyledChrome from "./views/Chrome";

const Chrome = props => {
  const { children } = props;

  return (
    <StyledChrome>
      { children }
    </StyledChrome>
  );
}

export default Chrome;
