import React from "react";

import { AppSystem, Metadata } from "@nowadays/Core";
import { useHomePageData } from "@nowadays/Graph/hooks";
import { Main as MainTheme, ThemeProvider } from "@nowadays/UI/Themes";

import Chrome from "./components/Chrome";
import ContentDesk from "./components/ContentDesk";
import Footer from "./components/Footer";
import Header from "./components/Header";

const App = props => {
  const { children } = props;

  const { metadata } = useHomePageData();

  return (
    <ThemeProvider theme={MainTheme}>
      <AppSystem>
        <Metadata metadata={metadata}/>
        <Chrome>
          <ContentDesk>
            <Header/>
            { children }
          </ContentDesk>
          <Footer/>
        </Chrome>
      </AppSystem>
    </ThemeProvider>
  );
};

export default App;
