/*
 * Grid
 *
 * The following theme specification is defined according to the theme
 * specification of styled-bootstrap-grid.
 *
 * See: https://github.com/dragma/styled-bootstrap-grid
 *
 */
const grid = {};

grid.breakpoints = {
  xxl: 1440,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 575
};

grid.col = {
  // Columns should be separated by one base unit
  // when positioned next to one another.
  padding: 5
};

grid.container = {
  padding: 10,
  maxWidth: {
    veryGiant: 1141,
    giant: 1140,
    desktop: 960,
    tablet: 720,
    phone: 540,
    smaller: 540
  }
};

grid.row = {
  // Account for padding on columns.
  padding: 5
};

export default grid;
