import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Cell = styled.div.attrs(props => {
  const {
    className,
    padExtra
  } = props;

  return {
    className: classNames(className, "about__cell", {
      "about__cell--pad-extra": padExtra
    })
  };
})`
  ${({ theme: { borders, colors, space } }) => `
    background-image:
      linear-gradient(
        to bottom, ${ colors.white } 0%, ${ colors.white } 51%, transparent 51%
      );
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    box-shadow: ${ borders.top } ${ colors.white };
    padding:
      ${ space.xxSmall }
      ${ space.small }
      ${ space.xxSmall }
      ${ space.xxSmall };
    position: relative;

    &:first-child {
      background-image: none;
      box-shadow: none;
    }

    &.about__cell--pad-extra {
      padding:
        ${ space.large }
        ${ space.xLarge }
        ${ space.large }
        ${ space.large };
    }
  `};

  ${media.sm`
    ${ ({ theme: { space } }) => `
      && {
        &.about__cell--pad-extra {
          padding:
            ${ space.xLarge }
            ${ space.xxLarge }
            ${ space.xLarge }
            ${ space.xLarge };
        }
      }
    `}
  `}
`;

Cell.propTypes = {
  padExtra: PropTypes.bool
};

Cell.defaultProps = {
  padExtra: false
};

export default Cell;
