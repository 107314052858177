import React from "react";

import Aspirations from "./components/Aspirations";
import Contact from "./components/Contact";
import Instagram from "./components/Instagram";
import Overview from "./components/Overview";
import ScrollArea from "./components/ScrollArea";
import Skills from "./components/Skills";
import Cell from "./views/Cell";

const About = props => {

  return (
    <React.Fragment>
      <ScrollArea>
        <Cell padExtra>
          <Overview/>
        </Cell>
        <Cell padExtra>
          <Aspirations/>
        </Cell>
        <Cell padExtra>
          <Skills/>
        </Cell>
        <Cell padExtra>
          <Contact/>
        </Cell>
        <Cell>
          <Instagram/>
        </Cell>
      </ScrollArea>
    </React.Fragment>
  );
};

export default About;
