import classNames from "classnames";
import styled from "styled-components";

const CarouselSlide = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__aspirations__carousel-slide")
  };
})`
  flex: 0 0 100%;
  position: relative;
  user-select: none;
`;

CarouselSlide.propTypes = {};

CarouselSlide.defaultProps = {};

export default CarouselSlide;
