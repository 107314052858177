import classNames from "classnames";
import styled from "styled-components";

const Wrapper = styled.div.attrs(props => {
  const {
    className,
  } = props;

  return {
    className: classNames(className, "about__scroll-area__wrapper")
  };
})`
  ${() => `
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
  `}
`;

Wrapper.propTypes = {};

Wrapper.defaultProps = {};

export default Wrapper;
