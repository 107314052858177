import classNames from "classnames";
import styled from "styled-components";

const NavButtons = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "about__aspirations__carousel-nav-buttons")
  };
})`
  ${ ({ theme: { space } }) => `
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    position: absolute;
    right: 0;
  `}
`;

NavButtons.propTypes = {};

NavButtons.defaultProps = {};

export default NavButtons;
