import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const AboutWindowBackdrop = styled.div.attrs(props => {
  const {
    className,
    open
  } = props;

  return {
    className: classNames(
      className,
      "chrome__content-desk__about-panel-backdrop", {
        "chrome__content-desk__about-panel-backdrop--is-open": open
      }
    ),
    role: "backdrop",
    tabIndex: "-1"
  };
})`
  ${ ({ theme: { chrome } }) => `
    --aboutPanelWidth: ${ chrome.mobile.aboutPanelWidth };
    bottom: 0;
    cursor: pointer;
    display: none;
    left: calc(var(--aboutPanelWidth) * -1);
    outline: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;

    &.chrome__content-desk__about-panel-backdrop--is-open {
      display: block;
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        --aboutPanelWidth: ${ chrome.aboutPanelWidth };
        left: calc(var(--aboutPanelWidth) * -1);
      }
    `}
  `}
`;

AboutWindowBackdrop.propTypes = {
  open: PropTypes.bool.isRequired
};

AboutWindowBackdrop.defaultProps = {};

export default AboutWindowBackdrop;
