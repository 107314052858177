import React, { useEffect, useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { setupWheelGestures } from "embla-carousel-wheel-gestures";
import BlockContent from "@sanity/block-content-to-react";

import { useCompanyData } from "@nowadays/Graph/hooks";
import { XLargeParagraph } from "@nowadays/UI/Paragraph";

import StyledAspirations from "./views/Aspirations";
import Carousel from "./views/Carousel";
import CarouselSlide from "./views/CarouselSlide";
import CarouselWrapper from "./views/CarouselWrapper";
import NavButton from "./views/NavButton";
import NavButtons from "./views/NavButtons";
import Title from "./views/Title";

const serializers = {
  types: {
    block: ({ children }) => (
      <XLargeParagraph expanded withMeasure>
        { children }
      </XLargeParagraph>
    )
  }
};

const Aspirations = props => {
  const { aspirations } = useCompanyData();

  const [emblaRef, embla] = useEmblaCarousel({
    loop: true
  });

  const [visibleAspirationIndex, setVisibleAspirationIndex] = useState(0);

  useEffect(() => embla && setupWheelGestures(embla), [embla]);

  useEffect(() => {
    if (!embla) { return; }

    const updateSelectedAspirationIndex = () => {
      setVisibleAspirationIndex(embla.selectedScrollSnap());
    };

    embla.on("select", updateSelectedAspirationIndex);
  }, [embla]);

  return (
    <StyledAspirations>
      <Title>
        Aspirations { visibleAspirationIndex + 1 }/{ aspirations.length }
      </Title>
      <CarouselWrapper ref={emblaRef}>
        <Carousel>
          {
            aspirations.map((aspiration, i) => (
              <CarouselSlide key={`about__aspiration-k-${ i }`}>
                <BlockContent
                  blocks={aspiration._rawText}
                  serializers={serializers}
                />
              </CarouselSlide>
            ))
          }
        </Carousel>
      </CarouselWrapper>
      <NavButtons>
        <NavButton
          direction="left"
          onClick={() => embla.scrollPrev()}
          title="Previous Aspiration"
        />
        <NavButton
          direction="right"
          onClick={() => embla.scrollNext()}
          title="Next Aspiration"
        />
      </NavButtons>
    </StyledAspirations>
  );
};

export default Aspirations;
