import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { IconURL } from "@nowadays/UI/Icon";
import { svg } from "@nowadays/UI/Utils";

const InstagramLink = styled.a.attrs(props => {
  const {
    className
  } = props;

  return {
    "aria-label": "Instagram Link",
    className: classNames(className, "chrome__footer__instagram-link")
  };
})`
  ${({ theme: { colors, lineHeights } }) => `
    align-self: center;
    background-image:
      url(${ svg.replaceColors(IconURL.Instagram, { fill: colors.white }) });
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: ${ lineHeights.xSmall };
    text-decoration: none;
    width: ${ lineHeights.xSmall };
  `}

  ${media.sm`
    ${ ({ theme: { space } }) => `
      height: ${ space.small };
      width: ${ space.small };
    `}
  `}
`;

InstagramLink.propTypes = {};

InstagramLink.defaultProps = {};

export default InstagramLink;
