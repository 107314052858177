import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { useCompanyData } from "@nowadays/Graph/hooks";
import { Anchor } from "@nowadays/UI/Button";
import { ResponsiveParagraph } from "@nowadays/UI/Paragraph";

import Title from "./views/Title";

const serializers = {
  types: {
    block: ({ children }) => (
      <ResponsiveParagraph xs="medium" sm="xLarge" withMeasure>
        { children }
      </ResponsiveParagraph>
    )
  },
  marks: {
    link: ({ children, mark }) => (
      mark.newTab ? (
        <Anchor href={mark.href} target="_blank" rel="noopener noreferrer">
          { children }
        </Anchor>
      ) : (
        <Anchor href={mark.href}>
          { children }
        </Anchor>
      )
    )
  }
};

const Overview = props => {
  const { name, _rawDescription } = useCompanyData();

  return (
    <React.Fragment>
      <Title>
        { name }
      </Title>
      <BlockContent blocks={_rawDescription} serializers={serializers}/>
    </React.Fragment>
  );
};

export default Overview;
