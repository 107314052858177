/*
 * Font Weights
 *
 */
const fontWeights = [
  400,
  700
];

fontWeights.normal = fontWeights[0];
fontWeights.bold = fontWeights[1];

export default fontWeights;
