import classNames from "classnames";
import styled, { css } from "styled-components";

import Paragraph from "./Paragraph";

export const XXLargeParagraphStyles = css`
  ${({ theme: { fontSizes, lineHeights, measures }}) => `
    font-size: ${ fontSizes.xxLarge };
    line-height: ${ lineHeights.xxLarge };

    & + & {
      margin-top: ${ lineHeights.xxLarge };
    }

    &.paragraph--with-measure {
      max-width: ${ measures.xxLarge };
      width: 100%;
    }
  `}
`;

const XXLargeParagraph = styled(Paragraph).attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "paragraph__xx-large-paragraph")
    };
  }
)`
  ${ XXLargeParagraphStyles }
`;

XXLargeParagraph.propTypes = {
  ...Paragraph.propTypes
};

XXLargeParagraph.defaultProps = {};

export default XXLargeParagraph;
