import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const TRANSITION_NAME = `chrome__content-desk`;

const StyledContentDesk = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(className, "chrome__content-desk")
    };
  }
)`
  ${ ({ theme: { chrome }, transitionMs }) => `
    display: flex;
    justify-content: flex-end;
    transform: translate3d(0, 0, 0);
    // Give it a little tactile feeling.
    //transition-delay: ${ ContentDesk.ARTIFICIAL_DELAY_MS }ms;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transform-style: preserve-3d;

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active,
    &.${ TRANSITION_NAME }-exit-active {
      transition:
        transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    };

    &.${ TRANSITION_NAME }-appear,
    &.${ TRANSITION_NAME }-enter {
      transform: translateX(0);
    };

    &.${ TRANSITION_NAME }-appear-active,
    &.${ TRANSITION_NAME }-enter-active {
      transform: translateX(${ chrome.mobile.aboutPanelWidth });
    };

    &.${ TRANSITION_NAME }-appear-done,
    &.${ TRANSITION_NAME }-enter-done {
      transform: translateX(${ chrome.mobile.aboutPanelWidth });
    };

    &.${ TRANSITION_NAME }-exit {
      transform: translateX(${ chrome.mobile.aboutPanelWidth });
    };

    // Careful with these && declarations. These will override mobile settings
    // and other settings that follow here.
    && {
      &.${ TRANSITION_NAME }-exit-active {
        transform: translateX(0);
      };
    }
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      &.${ TRANSITION_NAME }-appear-active,
      &.${ TRANSITION_NAME }-appear-done,
      &.${ TRANSITION_NAME }-enter-active,
      &.${ TRANSITION_NAME }-enter-done,
      &.${ TRANSITION_NAME }-exit {
        transform: translateX(${ chrome.aboutPanelWidth });
      };
    `}
  `}
`;

StyledContentDesk.propTypes = {};

StyledContentDesk.defaultProps = {};

const ContentDesk = props => {
  const { children, open, transitionMs, ...passdownProps } = props;

  return (
    <CSSTransition
      appear
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      in={ open }
      timeout={{
        appear: transitionMs + ContentDesk.ARTIFICIAL_DELAY_MS,
        enter: transitionMs + ContentDesk.ARTIFICIAL_DELAY_MS,
        exit: transitionMs + ContentDesk.ARTIFICIAL_DELAY_MS
      }}
    >
      <StyledContentDesk transitionMs={ transitionMs } { ...passdownProps }>
        { children }
      </StyledContentDesk>
    </CSSTransition>
  );
};

ContentDesk.ARTIFICIAL_DELAY_MS = 64;

ContentDesk.propTypes = {
  open: PropTypes.bool.isRequired,
  transitionMs: PropTypes.number.isRequired
};

ContentDesk.defaultProps = {};

export default ContentDesk;
